import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => { };
const INITIAL_STATE = {
    unreadAnnouncementCount: 0,
    todoAssignmentCount: 0,
};
const AppContext = createContext(INITIAL_STATE);
const SetAppContext = createContext(noop);
export const AppContextProvider = ({ children }) => {
    const [state, setState] = useState(INITIAL_STATE);
    return (_jsx(AppContext.Provider, { value: state, children: _jsx(SetAppContext.Provider, { value: setState, children: children }) }));
};
export const useAppContext = () => useContext(AppContext);
export const useSetAppContext = () => useContext(SetAppContext);
