var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApolloClient, InMemoryCache, createHttpLink, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getAuth } from 'firebase/auth';
import { osVersion } from 'react-device-detect';
import { API_HEADER_KEYS } from '../constants/mikan-bff';
import { config } from '../config';
import { UnauthorizedError } from '../errors/unauthorized-error';
export const MIKAN_BFF_TIMEOUT_MILLISECONDS = 5 * 1000;
const httpLink = createHttpLink({
    uri: config.get('MIKAN_BFF_ENDPOINT') + '/graphql',
    headers: {
        Accept: 'application/json',
        [API_HEADER_KEYS.CONTENT_TYPE]: 'application/json',
        [API_HEADER_KEYS.X_DEVICE_PLATFORM]: PLATFORM,
        [API_HEADER_KEYS.X_DEVICE_APP_BUNDLE_ID]: APP_NAME,
        [API_HEADER_KEYS.X_DEVICE_APP_VERSION]: APP_VERSION,
        [API_HEADER_KEYS.X_DEVICE_OS_VERSION]: osVersion,
    },
});
const authLink = setContext((_1, _a) => __awaiter(void 0, [_1, _a], void 0, function* (_, { headers }) {
    var _b;
    const idToken = yield ((_b = getAuth().currentUser) === null || _b === void 0 ? void 0 : _b.getIdToken());
    if (!idToken) {
        throw new UnauthorizedError();
    }
    return {
        headers: Object.assign(Object.assign({}, headers), { [API_HEADER_KEYS.AUTHORIZATION]: `Bearer ${idToken}` }),
    };
}));
export const apolloClient = new ApolloClient({
    link: from([authLink, httpLink]),
    cache: new InMemoryCache(),
});
