export const API_HEADER_KEYS = {
    AUTHORIZATION: 'Authorization',
    X_FIREBASE_UID: 'X-Firebase-Uid',
};
export const API_PATHS = {
    DAILY_ROOM_RANKINGS: '/v1/school/daily_room_rankings',
    DAILY_USER_RANKINGS: '/v1/school/daily_user_rankings',
    ROOMS: '/v1/rooms',
    SESSION: '/v1/school/session',
    SIGN_IN: '/v1/school_users/sign_in',
    USERS: '/v1/users',
    USERS_ME: '/v1/users/me',
    USER_SCHOOLS: '/v1/users/me/school_users',
    WEEKLY_ROOM_RANKINGS: '/v1/school/weekly_room_rankings',
    WEEKLY_USER_RANKINGS: '/v1/school/weekly_user_rankings',
    // USERS のパスに繋げて呼び出す
    DAILY_CATEGORY_LEARNINGS: '/daily_category_learnings',
};
