export const COLLECTION_NAMES = {
    BOOKS: 'books',
    CHAPTERS: 'chapters',
    DAILY_STUDY_RECORDS: 'daily_study_records',
    EXAMS: 'exams',
    QUESTIONS: 'questions',
    RESULTS: 'results',
    STUDENTS: 'students',
    STUDENT_EXAM_RESULTS: 'student_exam_results',
    STUDIED_BOOKS: 'studied_books',
    STUDIED_CHAPTERS: 'studied_chapters',
    STUDIED_WORDS: 'studied_words',
    TAGS: 'tags',
    USERS: 'users',
    WORDS: 'words',
};
export const FIELD_NAMES = {
    BEST: 'best',
    EXAM_ID: 'exam_id',
    MAX_SCORE: 'max_score',
    ORDER_NUMBER: 'order_number',
    ROOMS: 'rooms',
    STUDIED_AT: 'studied_at',
    UID: 'uid',
    USER_EXAM_INDEX: 'user_exam_index',
};
export const BOOK_TAGS = {
    BUSINESS_ENGLISH: 'businessEnglish',
    CONTINUATION: 'continuation',
    DAILY_COMMUNICATION: 'dailyCommunication',
    EIKEN: 'eiken',
    ELEMENTARY_AND_JUNIOR_HIGH: 'elementaryAndJuniorHigh',
    EXAM: 'exam',
    FREE: 'free',
    JUKEN: 'juken',
    NEW: 'new',
    PRO: 'pro',
    SCHOOL_BOOKS: 'schoolBooks',
    STUDY_ABROAD: 'studyAbroad',
    TEXT_BOOK: 'textBook',
    TOEIC: 'toeic',
    USER_GENERATED: 'userGenerated',
};
export const BOOK_TAG_DOC_IDS = {
    [BOOK_TAGS.BUSINESS_ENGLISH]: 'a73b7e4e-6012-409e-99bf-4ae545239eba',
    [BOOK_TAGS.DAILY_COMMUNICATION]: 'f703636b-3576-4202-bd74-a570a6550059',
    [BOOK_TAGS.EIKEN]: '8cb5d153-2d38-4a05-be2d-609cd0601398',
    [BOOK_TAGS.ELEMENTARY_AND_JUNIOR_HIGH]: 'c7b26cff-45e2-42b8-9bba-fcfdad7ae16a',
    [BOOK_TAGS.JUKEN]: '4b4b4baa-cc53-4256-a32f-4f6feab08ce0',
    [BOOK_TAGS.PRO]: 'dcc76f82-c1f0-4a73-86d0-c574cea4a913',
    [BOOK_TAGS.STUDY_ABROAD]: '27044649-ce49-4d70-8c7a-d483a70963a5',
    [BOOK_TAGS.TOEIC]: '407b63ba-b9bb-40ab-bf82-e476d32ab17e',
    [BOOK_TAGS.TEXT_BOOK]: '90cd15fc-bccb-4623-b710-4ebb9b270cbe',
    [BOOK_TAGS.USER_GENERATED]: 'userGenerated',
};
export const BOOK_TAG_TITLES = {
    [BOOK_TAGS.BUSINESS_ENGLISH]: 'ビジネス英語',
    [BOOK_TAGS.CONTINUATION]: '続きから',
    [BOOK_TAGS.DAILY_COMMUNICATION]: '日常英会話',
    [BOOK_TAGS.EIKEN]: '英検®',
    [BOOK_TAGS.ELEMENTARY_AND_JUNIOR_HIGH]: '中学英語',
    [BOOK_TAGS.EXAM]: 'おすすめのリスニング・リーディング対策',
    [BOOK_TAGS.FREE]: '無料',
    [BOOK_TAGS.JUKEN]: '大学受験',
    [BOOK_TAGS.NEW]: '新着',
    [BOOK_TAGS.PRO]: 'PRO限定',
    [BOOK_TAGS.SCHOOL_BOOKS]: '指定教材',
    [BOOK_TAGS.STUDY_ABROAD]: '海外留学',
    [BOOK_TAGS.TEXT_BOOK]: '教科書',
    [BOOK_TAGS.TOEIC]: 'TOEIC®',
    [BOOK_TAGS.USER_GENERATED]: 'my単語帳',
};
export const BOOK_TAG_SUB_TITLES = {
    [BOOK_TAGS.CONTINUATION]: '継続は力なり',
    [BOOK_TAGS.NEW]: '新着関連',
    [BOOK_TAGS.FREE]: '無料関連',
    [BOOK_TAGS.EXAM]: '実践対策におすすめ',
    [BOOK_TAGS.SCHOOL_BOOKS]: '登録している学校・塾',
    [BOOK_TAGS.TEXT_BOOK]: '学校のテスト対策に',
};
export const SOKUDOKU_BOOK_IDS = new Set([
    'cd530b05-595c-4eef-891c-93c8d4103a2d',
    '7af5f5fd-f324-4bfb-a273-033b32acaba2',
]);
export const VISIBILITIES = {
    GONE: 'gone',
    INVISIBLE: 'invisible',
    VISIBLE: 'visible',
};
export const MASTER_STATUSES = {
    NOT_YET: 'not_yet',
    MASTERED: 'mastered',
    MASTERED_AND_SHOWN: 'mastered_and_shown',
};
export const MASTER_RATIOS = {
    PERFECT: 1.0,
    CERTAIN: 0.7,
    UNCERTAIN: 0.4,
    WEAK: 0.2,
    NOT_YET: 0.0,
};
export const MASTER_RATIO_IDS = {
    [MASTER_RATIOS.PERFECT]: 'perfect',
    [MASTER_RATIOS.CERTAIN]: 'certain',
    [MASTER_RATIOS.UNCERTAIN]: 'uncertain',
    [MASTER_RATIOS.WEAK]: 'weak',
    [MASTER_RATIOS.NOT_YET]: 'not-yet',
};
export const MASTER_RATIO_NAMES = {
    [MASTER_RATIOS.PERFECT]: '覚えた',
    [MASTER_RATIOS.CERTAIN]: 'ほぼ覚えた',
    [MASTER_RATIOS.UNCERTAIN]: 'うろ覚え',
    [MASTER_RATIOS.WEAK]: '苦手',
    [MASTER_RATIOS.NOT_YET]: '未学習',
};
export const EVALUATIONS = {
    EXCELLENT: 3,
    GREAT: 2,
    GOOD: 1,
    BAD: -3,
    NONE: 0,
    UNKNOWN: -5,
};
// 解答時間による評価をする際に利用する閾値
export const EVALUATION_THRESHOLD_TIMES = {
    EXCELLENT: 1000,
    GREAT: 1500,
};
export const EXAM_EVALUATIONS = {
    EXCELLENT: 'excellent',
    GREAT: 'great',
    GOOD: 'good',
    BAD: 'bad',
    NONE: 'none',
    UNKNOWN: 'unknown',
};
export const EXAM_STATUSES = {
    PERFECT: 'perfect',
    GREAT: 'great',
    WIP: 'wip',
    NOT_YET: 'not-yet',
    // 宿題が設定されている場合のステータス
    ASSIGNMENT_NOT_STARTED: 'assignment-not-started',
    ASSIGNMENT_COMPLETED: 'assignment-completed',
};
export const FIRESTORE_ERROR_CODES = {
    cancelled: 'cancelled',
    unknown: 'unknown',
    'invalid-argument': 'invalid-argument',
    'deadline-exceeded': 'deadline-exceeded',
    'not-found': 'not-found',
    'already-exists': 'already-exists',
    'permission-denied': 'permission-denied',
    'resource-exhausted': 'resource-exhausted',
    'failed-precondition': 'failed-precondition',
    aborted: 'aborted',
    'out-of-range': 'out-of-range',
    unimplemented: 'unimplemented',
    internal: 'internal',
    unavailable: 'unavailable',
    'data-loss': 'data-loss',
    unauthenticated: 'unauthenticated',
};
export const QUESTION_TYPE_TITLES = {
    translation: '（音声再生無し）英日4択',
    translation_with_en_choices: '日英4択',
    translation_fill_in_blanks: '空所補充4択',
    reading: '発話解答読み上げ',
    shadowing: '発話解答シャドーイング',
    listening_fill_in_blanks: '空所補充リスニング4択',
    listening: 'リスニング4択',
    speaking_fill_in_blanks: '発話解答空所補充あり',
    typing_fill_in_blanks: 'タイピング解答空所補充',
    listening_fill_in_blanks_by_typing: 'タイピング空所補充リスニング',
    translation_with_pronunciation_fill_in_blanks: '空所補充リスニング4択',
    translation_with_jp_choices: '英日4択',
    translation_with_underline: '下線部和訳',
    rearrange_english_choices: '語句整序',
    none: '',
};
export const QUIZ_STATUSES = {
    PERFECT: 'perfect',
    GREAT: 'great',
    WIP: 'wip',
    NOT_YET: 'not-yet',
};
