var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
/**
 * window.screen.orientation.type が取得できないブラウザ (safari) のための OrientationType 判定処理
 * @param angle window.orientation の値 (undefined の場合, PC safari とみなし横画面判定とする)
 * TODO: window.orientation は deprecated だが, 代替手段がない & mobile safari ではまだ使用可能なのでひとまず使用する
 * @returns angle に応じた OrientationType
 */
const getOrientationTypeFromAngle = (angle) => {
    if (typeof angle === 'undefined') {
        return 'landscape-primary';
    }
    switch (angle) {
        case 0:
            return 'portrait-primary';
        case 90:
            return 'landscape-secondary';
        case 180:
            return 'portrait-secondary';
        case -90:
            return 'landscape-primary';
        default:
            return 'portrait-primary';
    }
};
const initState = {
    innerHeight: window.innerHeight,
    innerWidth: window.innerWidth,
    orientation: ((_a = window.screen.orientation) === null || _a === void 0 ? void 0 : _a.type) || getOrientationTypeFromAngle(window.orientation),
};
export const ScreenContext = createContext(initState);
export const useScreen = () => {
    const state = useContext(ScreenContext);
    return state;
};
export const ScreenContextProvider = ({ children }) => {
    const [state, setState] = useState(Object.assign({}, initState));
    // 画面の向き変更検知
    useEffect(() => {
        const handleScreenOrientation = () => {
            setState((currentState) => {
                var _a;
                return (Object.assign(Object.assign({}, currentState), { orientation: ((_a = window.screen.orientation) === null || _a === void 0 ? void 0 : _a.type) || getOrientationTypeFromAngle(window.orientation) }));
            });
        };
        window.addEventListener('orientationchange', handleScreenOrientation);
        return () => {
            window.removeEventListener('orientationchange', handleScreenOrientation);
        };
    }, []);
    // 画面サイズの変更検知
    useEffect(() => {
        const handleResize = () => {
            setState((currentState) => (Object.assign(Object.assign({}, currentState), { innerHeight: window.innerHeight, innerWidth: window.innerWidth })));
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    // 画面の active - inactive 変更検知
    useEffect(() => {
        const handleVisibilityChange = () => {
            // iOS Safari のバグっぽい挙動で外部リンクを開いたあとに戻ってくると画面の高さが変わってしまうため、
            // 一時的に viewport-fit=cover を適用して画面をリサイズ → 1秒後に戻すようにする
            // TODO: 常に viewport-fit=cover を入れておきたいが、WebView 用の画面サイズ調整が必要なので別途対応したい
            const viewport = document.querySelector('meta[name=viewport]');
            const viewportContent = viewport === null || viewport === void 0 ? void 0 : viewport.getAttribute('content');
            if (viewport === null || typeof viewportContent !== 'string') {
                return;
            }
            const viewportFitCover = ',viewport-fit=cover';
            viewport.setAttribute('content', viewportContent + viewportFitCover);
            setTimeout(() => {
                viewport.setAttribute('content', viewportContent.replace(viewportFitCover, ''));
            }, 1000);
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    return _jsx(ScreenContext.Provider, { value: state, children: children });
};
