var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HTTP_METHODS } from '../../constants/http';
import { API_HEADER_KEYS, API_PATHS } from '../../constants/mikan-rails-api';
import { NetworkError } from '../../errors/network-error';
import { NotFoundError } from '../../errors/not-found-error';
import { VALIDATION_ITEMS, ValidationError } from '../../errors/validation-error';
import User from '../../models/user';
import mikanRailsApi from '../../utils/mikan-rails-api';
export const fetchUserByFirebaseUid = (firebaseUid) => __awaiter(void 0, void 0, void 0, function* () {
    if (!firebaseUid) {
        throw new ValidationError(VALIDATION_ITEMS.EMPTY_USER_ID);
    }
    const res = yield mikanRailsApi.request({
        method: HTTP_METHODS.GET,
        headers: { [API_HEADER_KEYS.X_FIREBASE_UID]: firebaseUid },
        url: `${API_PATHS.USERS}/${firebaseUid}`,
    });
    if (!res.data) {
        throw new NetworkError();
    }
    if (!res.data.user) {
        throw new NotFoundError('ユーザーが存在しません');
    }
    return User.createFromJSON(res.data.user);
});
/**
 * schoolUserSessionSignIn のあとに呼ぶ、ユーザー作成のAPI
 * @param firebaseUid firebase auth のユーザー ID
 * @returns 認証トークン
 */
export const createUser = (firebaseUid) => __awaiter(void 0, void 0, void 0, function* () {
    if (!firebaseUid) {
        throw new ValidationError(VALIDATION_ITEMS.EMPTY_USER_ID);
    }
    const res = yield mikanRailsApi.request({
        method: HTTP_METHODS.POST,
        url: API_PATHS.USERS,
        headers: { [API_HEADER_KEYS.X_FIREBASE_UID]: firebaseUid },
        data: {
            firebase_uid: firebaseUid,
        },
    });
    if (!res.data) {
        throw new NetworkError();
    }
    if (!res.data.user) {
        throw new NotFoundError('ユーザーが存在しません');
    }
    return User.createFromJSON(res.data.user);
});
/**
 * ユーザーのニックネーム更新API
 * TODO: 更新する項目が増えてきたら updateUser にして複数のデータを更新可能にする
 * @param firebaseUid firebase auth のユーザー ID
 * @param nickname ニックネーム
 * @returns 更新後のユーザー
 */
export const updateUserNickname = (firebaseUid, nickname) => __awaiter(void 0, void 0, void 0, function* () {
    if (!firebaseUid) {
        throw new ValidationError(VALIDATION_ITEMS.EMPTY_USER_ID);
    }
    if (!nickname) {
        throw new ValidationError(VALIDATION_ITEMS.EMPTY_NICKNAME);
    }
    const res = yield mikanRailsApi.request({
        method: HTTP_METHODS.PUT,
        url: API_PATHS.USERS_ME,
        headers: { [API_HEADER_KEYS.X_FIREBASE_UID]: firebaseUid },
        data: {
            nickname,
        },
    });
    if (!res.data) {
        throw new NetworkError();
    }
    if (!res.data.user) {
        throw new NotFoundError('ユーザーが存在しません');
    }
    return User.createFromJSON(res.data.user);
});
