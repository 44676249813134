import { BaseError } from './base-error';
export const BAD_REQUEST_MESSAGES = {
    INSUFFICIENT_INFORMATION: 'insufficient information',
};
export class BadRequestError extends BaseError {
    constructor(message, code) {
        super(message || 'リクエストが不正です');
        this.name = new.target.name;
        this.code = code;
    }
    get isInSufficientInformation() {
        return this.code === BAD_REQUEST_MESSAGES.INSUFFICIENT_INFORMATION;
    }
}
