import { QuizWordStudiedRecordEvaluation } from '../gql/generated/graphql';
import { EVALUATIONS } from './firestore';
export const API_HEADER_KEYS = {
    AUTHORIZATION: 'Authorization',
    CONTENT_TYPE: 'Content-Type',
    X_DEVICE_PLATFORM: 'X-Device-Platform',
    X_DEVICE_APP_BUNDLE_ID: 'X-Device-App-Bundle-ID',
    X_DEVICE_OS_VERSION: 'X-Device-OS-Version',
    X_DEVICE_APP_VERSION: 'X-Device-App-Version',
};
export const API_PATHS = {
    SIGNIN: '/v1/signin',
    TOKEN: '/v1/token',
    STUDIED_RECORDS: (bookId, chapterId) => `/v1/books/${bookId}/chapters/${chapterId}/studied_records`,
};
export const TASK_TYPES = {
    AUDIO: 'AUDIO',
    EXAM: 'EXAM',
};
export const TASK_STATUSES = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    COMPLETED: 'COMPLETED',
};
export const QUIZ_STATUSES = {
    PERFECT: 'perfect',
    GREAT: 'great',
    WIP: 'wip',
    NOT_YET: 'not-yet',
};
// 解答時間による評価をする際に利用する閾値
export const QUIZ_THRESHOLD_TIMES = {
    EXCELLENT: 1000,
    GREAT: 1500,
};
export const QUIZ_EVALUATIONS = {
    EXCELLENT: 'excellent',
    GREAT: 'great',
    GOOD: 'good',
    BAD: 'bad',
    NONE: 'none',
    UNKNOWN: 'unknown',
};
// graphql のスピーキング評価と単語の学習評価のマッピング
export const SPEECH_RECOGNITION_EVALUATION_MAP = new Map([
    [QuizWordStudiedRecordEvaluation.Excellent, EVALUATIONS.EXCELLENT],
    [QuizWordStudiedRecordEvaluation.Great, EVALUATIONS.GREAT],
    [QuizWordStudiedRecordEvaluation.Good, EVALUATIONS.GOOD],
    [QuizWordStudiedRecordEvaluation.Bad, EVALUATIONS.BAD],
]);
