import axios, { AxiosError } from 'axios';
import { HTTP_METHODS, HTTP_STATUSES } from '../constants/http';
import { config } from '../config';
import { BadGatewayError } from '../errors/bad-gateway-error';
import { BadRequestError } from '../errors/bad-request-error';
import { ForbiddenError } from '../errors/forbidden-error';
import { InternalServerError } from '../errors/internal-server-error';
import { MethodNotAllowedError } from '../errors/method-not-allowed';
import { NotFoundError } from '../errors/not-found-error';
import { NotImplementedError } from '../errors/not-implemented-error';
import { TimeoutError } from '../errors/timeout-error';
import { UnauthorizedError } from '../errors/unauthorized-error';
import { UnknownError } from '../errors/unknown-error';
const defaultOptions = {
    baseURL: config.get('MIKAN_BFF_ENDPOINT'),
    method: HTTP_METHODS.GET,
    headers: {
        Accept: 'application/json',
    },
    timeout: 15000, // 暫定対応 https://linear.app/mikan/issue/FS-282
};
const instance = axios.create(defaultOptions);
instance.interceptors.response.use(undefined, (error) => {
    var _a;
    if (!(error instanceof AxiosError)) {
        throw error;
    }
    switch ((_a = error.response) === null || _a === void 0 ? void 0 : _a.status) {
        case HTTP_STATUSES.BAD_REQUEST:
            throw new BadRequestError();
        case HTTP_STATUSES.UNAUTHORIZED:
            throw new UnauthorizedError();
        case HTTP_STATUSES.FORBIDDEN:
            throw new ForbiddenError();
        case HTTP_STATUSES.NOT_FOUND:
            throw new NotFoundError();
        case HTTP_STATUSES.METHOD_NOT_ALLOWED:
            throw new MethodNotAllowedError();
        case HTTP_STATUSES.TIMEOUT:
            throw new TimeoutError();
        case HTTP_STATUSES.INTERNAL_SERVER_ERROR:
            throw new InternalServerError();
        case HTTP_STATUSES.NOT_IMPLEMENTED:
            throw new NotImplementedError();
        case HTTP_STATUSES.BAD_GATEWAY:
            throw new BadGatewayError();
        default:
            throw new UnknownError();
    }
});
export default instance;
