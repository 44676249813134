import React from 'react';
import shallowEqual from 'shallowequal';
import { notifyError } from '../../utils/errorReporting';
export class ErrorBoundary extends React.PureComponent {
    constructor() {
        super(...arguments);
        this.state = {
            hasError: false,
        };
    }
    componentDidCatch(error, errorInfo) {
        var _a;
        notifyError(error, { componentStack: (_a = errorInfo.componentStack) !== null && _a !== void 0 ? _a : undefined });
        this.setState({
            hasError: true,
        });
    }
    /**
     * エラー状態から Routing 等で children の内容が変化した場合は復帰の可能性を考慮してエラー状態をクリアする
     */
    componentDidUpdate(prevProps) {
        if (this.state.hasError === true && !shallowEqual(this.props.children, prevProps.children)) {
            this.setState({
                hasError: false,
            });
        }
    }
    render() {
        const { children, fallbackElement } = this.props;
        return this.state.hasError && fallbackElement != null ? fallbackElement : children;
    }
}
