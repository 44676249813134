class School {
    constructor(name, schoolCode, rooms, userIdentifier) {
        this.name = name;
        this.schoolCode = schoolCode;
        this.rooms = rooms;
        this.userIdentifier = userIdentifier;
    }
    get allRoomsBookIds() {
        return this.rooms.flatMap((room) => room.books);
    }
}
School.createFromJSON = (json) => {
    if (!json) {
        return;
    }
    const rooms = [];
    if (json.rooms && Array.isArray(json.rooms)) {
        json.rooms.forEach((roomData) => {
            if (!roomData.id) {
                return;
            }
            rooms.push({
                id: roomData.id,
                name: roomData.name || '',
                roomCode: roomData.room_code || '',
                books: roomData.books || [],
            });
        });
    }
    return new School(json.name, json.school_code || '', rooms, json.user_identifier || '');
};
export default School;
