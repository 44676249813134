import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { getLocationHistoryStackFromStorage, setLocationHistoryStackToStorage } from '../utils/app-storage';
import { AuthContext } from './AuthContext';
const initState = {
    isInitialized: false,
    isEnabledBack: false,
    isEnabledForward: false,
    locationStack: [],
};
export const BackForwardHistoryContext = createContext(initState);
export const useBackForwardHistory = () => {
    const state = useContext(BackForwardHistoryContext);
    return [state.isEnabledBack, state.isEnabledForward];
};
export const BackForwardHistoryContextProvider = ({ children }) => {
    const [state, setState] = useState(Object.assign({}, initState));
    const authState = useContext(AuthContext);
    const location = useLocation();
    useEffect(() => {
        var _a;
        const firebaseUid = (_a = authState === null || authState === void 0 ? void 0 : authState.firebaseUser) === null || _a === void 0 ? void 0 : _a.uid;
        if (!window.history.state || !firebaseUid) {
            return;
        }
        const locationKey = window.history.state.key;
        const locationIndex = window.history.state.idx || 0;
        const { locationStack } = state;
        let newLocationStack = locationStack;
        // mikan-web への初回アクセス時に実行されるページ履歴復元処理
        if (!state.isInitialized && locationKey) {
            newLocationStack = getLocationHistoryStackFromStorage(firebaseUid);
        }
        if (locationKey) {
            const stackIndex = newLocationStack.findIndex((val) => val === locationKey);
            // まだ stack にない (訪問したことのない) ページであれば,
            // 現在保持されているページ履歴から locationIndex 以降のものは消して新たに今のページを追加する
            if (stackIndex < 0) {
                newLocationStack = newLocationStack.slice(0, locationIndex - 1);
                newLocationStack = newLocationStack.concat(locationKey);
            }
        }
        // locationIndex が 0 の場合は先頭ページのため戻れない
        const newIsEnabledBack = locationIndex > 0;
        // locationIndex が保持されている locationStack の数と同じ場合は最後のページなので進めない
        const newIsEnabledForward = locationIndex < newLocationStack.length;
        // 更新されたときのために localStorage に履歴を保持しておく
        setLocationHistoryStackToStorage(firebaseUid, newLocationStack);
        setState({
            isInitialized: true,
            isEnabledBack: newIsEnabledBack,
            isEnabledForward: newIsEnabledForward,
            locationStack: newLocationStack,
        });
    }, [authState === null || authState === void 0 ? void 0 : authState.firebaseUser, location.pathname, location.search]);
    return _jsx(BackForwardHistoryContext.Provider, { value: state, children: children });
};
