import { changeSEAudioVolume, changeWordAudioVolume } from './audio';
import { generateUuidV4 } from './uuid';
export const STORAGE_KEYS = {
    CARD_LIMIT_TIME_PER_BOOK: 'CARD_LIMIT_TIME_PER_BOOK',
    CHOICES_LIMIT_TIME_PER_BOOK: 'CHOICES_LIMIT_TIME_PER_BOOK',
    COMPONENT_REFRESH_FLAGS: 'COMPONENT_REFRESH_FLAGS',
    CONTINUOUS_CORRECT_COUNT: 'CONTINUOUS_CORRECT_COUNT',
    DAILY_GOAL_COUNT: 'DAILY_GOAL_COUNT',
    DEVICE_ID: 'DEVICE_ID',
    DISPLAYING_JAPANESE_PER_BOOK: 'DISPLAYING_JAPANESE_PER_BOOK',
    HIDE_CHOICE_PER_BOOK: 'HIDE_CHOICE_PER_BOOK',
    LAST_LEARNING_CHAPTER_IDS_PER_BOOK: 'LAST_LEARNING_CHAPTER_IDS_PER_BOOK',
    LATEST_PLAYED_AUDIO_RESOURCE_ID_PER_BOOK: 'LATEST_PLAYED_AUDIO_RESOURCE_ID_PER_BOOK',
    LATEST_STUDIED_DATE: 'LATEST_STUDIED_DATE',
    LIMIT_TIME_PER_BOOK: 'LIMIT_TIME_PER_BOOK',
    LOCATION_HISTORY_STACK: 'LOCATION_HISTORY_STACK',
    SE_AUDIO_VOLUME: 'SE_AUDIO_VOLUME',
    STUDY_TYPE_PER_BOOK: 'STUDY_TYPE_PER_BOOK',
    STUDY_MODE_PER_BOOK: 'STUDY_MODE_PER_BOOK',
    TEST_TYPE_PER_BOOK: 'TEST_TYPE_PER_BOOK',
    TEST_TYPE_PER_STUDY_MODE: 'TEST_TYPE_PER_STUDY_MODE',
    WORD_AUDIO_VOLUME: 'WORD_AUDIO_VOLUME',
    WORD_COUNT_PER_LEARNING: 'WORD_COUNT_PER_LEARNING',
    QT_SETTINGS: 'QT_SETTINGS',
};
// 端末 ID (初めてアクセスされたときの UUID)
export const getDeviceIdFromStorage = () => {
    let deviceId = localStorage.getItem(STORAGE_KEYS.DEVICE_ID);
    if (typeof deviceId !== 'string' || deviceId.length === 0) {
        deviceId = generateUuidV4();
        setDeviceIdToStorage(deviceId);
        return deviceId;
    }
    return deviceId;
};
export const setDeviceIdToStorage = (deviceId) => {
    localStorage.setItem(STORAGE_KEYS.DEVICE_ID, deviceId);
};
// 連続正解数
export const getContinuousCorrectCountFromStorage = (firebaseUid) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    return Number(userData[STORAGE_KEYS.CONTINUOUS_CORRECT_COUNT]) || 0;
};
export const setContinuousCorrectCountToStorage = (firebaseUid, count) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    userData[STORAGE_KEYS.CONTINUOUS_CORRECT_COUNT] = count;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 教材ごとの最後に学習したチャプターの ID
export const getLastLearningChapterIdFromStorage = (firebaseUid, bookId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const chapterIdsPerBook = userData[STORAGE_KEYS.LAST_LEARNING_CHAPTER_IDS_PER_BOOK] || {};
    return chapterIdsPerBook[bookId];
};
export const setLastLearningChapterIdToStorage = (firebaseUid, bookId, chapterId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const chapterIdsPerBook = userData[STORAGE_KEYS.LAST_LEARNING_CHAPTER_IDS_PER_BOOK] || {};
    chapterIdsPerBook[bookId] = chapterId;
    userData[STORAGE_KEYS.LAST_LEARNING_CHAPTER_IDS_PER_BOOK] = chapterIdsPerBook;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 最新の学習日時
export const getLatestStudiedDateFromStorage = (firebaseUid) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const latestStudiedDateTimestamp = Number(userData[STORAGE_KEYS.LATEST_STUDIED_DATE]) || 0;
    return new Date(latestStudiedDateTimestamp);
};
export const setLatestStudiedDateToStorage = (firebaseUid, date) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    userData[STORAGE_KEYS.LATEST_STUDIED_DATE] = date.getTime().toString();
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 毎日の目標
export const getDailyGoalCountFromStorage = (firebaseUid) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const count = userData[STORAGE_KEYS.DAILY_GOAL_COUNT] || null;
    if (count == null) {
        setDailyGoalCountToStorage(firebaseUid, 30);
    }
    return count ? Number(count) : 30;
};
export const setDailyGoalCountToStorage = (firebaseUid, count) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    userData[STORAGE_KEYS.DAILY_GOAL_COUNT] = count;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 1回に学習する単語数
export const getWordCountPerLearningFromStorage = (firebaseUid) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const count = userData[STORAGE_KEYS.WORD_COUNT_PER_LEARNING] || null;
    if (count == null) {
        setWordCountPerLearningToStorage(firebaseUid, 10);
    }
    return count ? Number(count) : 10;
};
export const setWordCountPerLearningToStorage = (firebaseUid, count) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    userData[STORAGE_KEYS.WORD_COUNT_PER_LEARNING] = count;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 効果音の音量 (0.00 ~ 1.00)
export const getSEAudioVolumeFromStorage = (firebaseUid) => {
    var _a;
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const volume = (_a = userData[STORAGE_KEYS.SE_AUDIO_VOLUME]) !== null && _a !== void 0 ? _a : null;
    if (volume == null) {
        setSEAudioVolumeToStorage(firebaseUid, 0.5);
    }
    const parsedVolume = parseFloat(volume !== null && volume !== void 0 ? volume : 0.5);
    return parsedVolume;
};
export const setSEAudioVolumeToStorage = (firebaseUid, volume) => {
    changeSEAudioVolume(volume);
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    userData[STORAGE_KEYS.SE_AUDIO_VOLUME] = volume;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 発音の音量 (0.00 ~ 1.00)
export const getWordAudioVolumeFromStorage = (firebaseUid) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const volume = userData[STORAGE_KEYS.WORD_AUDIO_VOLUME];
    if (volume == null) {
        setWordAudioVolumeToStorage(firebaseUid, 0.5);
    }
    const parsedVolume = parseFloat(volume !== null && volume !== void 0 ? volume : 0.5);
    return parsedVolume;
};
export const setWordAudioVolumeToStorage = (firebaseUid, volume) => {
    changeWordAudioVolume(volume);
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    userData[STORAGE_KEYS.WORD_AUDIO_VOLUME] = volume;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// ページ履歴 (BackForwardHistoryContext で必要)
export const getLocationHistoryStackFromStorage = (firebaseUid) => {
    const userData = JSON.parse(sessionStorage.getItem(firebaseUid) || '{}');
    const stack = userData[STORAGE_KEYS.LOCATION_HISTORY_STACK] || null;
    if (stack == null) {
        setLocationHistoryStackToStorage(firebaseUid, []);
    }
    return stack ? stack : [];
};
export const setLocationHistoryStackToStorage = (firebaseUid, stack) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    userData[STORAGE_KEYS.LOCATION_HISTORY_STACK] = stack;
    sessionStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 教材ごとの学習タイプ
export const getStudyTypePerBookFromStorage = (firebaseUid, bookId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const studyTypePerBook = userData[STORAGE_KEYS.STUDY_TYPE_PER_BOOK] || {};
    return studyTypePerBook[bookId] || 'all';
};
export const setStudyTypePerBookToStorage = (firebaseUid, bookId, studyType) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const studyTypePerBook = userData[STORAGE_KEYS.STUDY_TYPE_PER_BOOK] || {};
    studyTypePerBook[bookId] = studyType;
    userData[STORAGE_KEYS.STUDY_TYPE_PER_BOOK] = studyTypePerBook;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 教材ごとの学習モード
export const getStudyModePerBookFromStorage = (firebaseUid, bookId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const studyModePerBook = userData[STORAGE_KEYS.STUDY_MODE_PER_BOOK] || {};
    return studyModePerBook[bookId] || 'choices';
};
export const setStudyModePerBookToStorage = (firebaseUid, bookId, studyMode) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const studyModePerBook = userData[STORAGE_KEYS.STUDY_MODE_PER_BOOK] || {};
    studyModePerBook[bookId] = studyMode;
    userData[STORAGE_KEYS.STUDY_MODE_PER_BOOK] = studyModePerBook;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
/**
 * 教材ごとの出題形式
 *
 * ユーザーごと × 教材ごと × StudyMode ごとに設定されている
 * {
 *   firebaseUid: {
 *     TEST_TYPE_PER_BOOK: {
 *       bookId: {
 *         card: enToJp,
 *         choices: listening,
 *         speaking: repeating,
 *         ...
 *       }
 *       ...
 *     }
 *     ...
 *   }
 * }
 * @param firebaseUid firebase ユーザー ID
 * @param bookId 教材 ID
 * @param studyMode 学習種別
 * @returns TestType
 */
export const getTestTypePerBookFromStorage = (firebaseUid, bookId, studyMode) => {
    const defaultValues = {
        card: 'enToJp',
        choices: 'enToJp',
        speaking: 'repeating',
        example: 'enToJp',
        typing: 'jpToEn',
    };
    const userDataString = localStorage.getItem(firebaseUid);
    const userData = JSON.parse(userDataString != null ? userDataString : '{}');
    if (!(userData instanceof Object)) {
        return defaultValues[studyMode];
    }
    const testTypePerBook = userData[STORAGE_KEYS.TEST_TYPE_PER_BOOK];
    if (!(testTypePerBook instanceof Object)) {
        return defaultValues[studyMode];
    }
    const testTypePerStudyMode = testTypePerBook[bookId];
    if (!(testTypePerStudyMode instanceof Object)) {
        return defaultValues[studyMode];
    }
    if (testTypePerStudyMode[studyMode] === undefined) {
        return defaultValues[studyMode];
    }
    return testTypePerStudyMode[studyMode];
};
export const setTestTypePerBookToStorage = (firebaseUid, bookId, testType, studyMode) => {
    const userDataString = localStorage.getItem(firebaseUid);
    let userData = JSON.parse(userDataString != null ? userDataString : '{}');
    if (!(userData instanceof Object)) {
        userData = {};
    }
    let testTypePerBook = userData[STORAGE_KEYS.TEST_TYPE_PER_BOOK];
    if (!(testTypePerBook instanceof Object)) {
        testTypePerBook = {};
    }
    let testTypePerStudyMode = testTypePerBook[bookId];
    if (!(testTypePerStudyMode instanceof Object)) {
        testTypePerStudyMode = {};
    }
    testTypePerStudyMode[studyMode] = testType;
    testTypePerBook[bookId] = testTypePerStudyMode;
    userData[STORAGE_KEYS.TEST_TYPE_PER_BOOK] = testTypePerBook;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 教材ごとの4択の制限時間
export const getChoicesLimitTimePerBookFromStorage = (firebaseUid, bookId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const choicesLimitTimePerBook = userData[STORAGE_KEYS.CHOICES_LIMIT_TIME_PER_BOOK] || {};
    return choicesLimitTimePerBook[bookId] || 10000;
};
export const setChoicesLimitTimePerBookToStorage = (firebaseUid, bookId, limitTime) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const choicesLimitTimePerBook = userData[STORAGE_KEYS.CHOICES_LIMIT_TIME_PER_BOOK] || {};
    choicesLimitTimePerBook[bookId] = limitTime * 1000;
    userData[STORAGE_KEYS.CHOICES_LIMIT_TIME_PER_BOOK] = choicesLimitTimePerBook;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 教材ごとのカードめくりの制限時間
export const getCardLimitTimePerBookFromStorage = (firebaseUid, bookId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const cardLimitTimePerBook = userData[STORAGE_KEYS.CARD_LIMIT_TIME_PER_BOOK] || {};
    return cardLimitTimePerBook[bookId] || 10000;
};
export const setCardLimitTimePerBookToStorage = (firebaseUid, bookId, limitTime) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const cardLimitTimePerBook = userData[STORAGE_KEYS.CARD_LIMIT_TIME_PER_BOOK] || {};
    cardLimitTimePerBook[bookId] = limitTime * 1000;
    userData[STORAGE_KEYS.CARD_LIMIT_TIME_PER_BOOK] = cardLimitTimePerBook;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 教材ごとの暗記シート機能 ON / OFF
export const getHideChoicePerBookFromStorage = (firebaseUid, bookId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const hideChoicePerBook = userData[STORAGE_KEYS.HIDE_CHOICE_PER_BOOK] || {};
    return hideChoicePerBook[bookId] || false;
};
export const setHideChoicePerBookToStorage = (firebaseUid, bookId, isHide) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const hideChoicePerBook = userData[STORAGE_KEYS.HIDE_CHOICE_PER_BOOK] || {};
    hideChoicePerBook[bookId] = isHide;
    userData[STORAGE_KEYS.HIDE_CHOICE_PER_BOOK] = hideChoicePerBook;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 教材ごとのタップせずに日本語を表示
export const getDisplayingJapanesePerBookFromStorage = (firebaseUid, bookId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const displayingJapanesePerBook = userData[STORAGE_KEYS.DISPLAYING_JAPANESE_PER_BOOK] || {};
    return displayingJapanesePerBook[bookId] || false;
};
export const setDisplayingJapanesePerBookToStorage = (firebaseUid, bookId, isShow) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const displayingJapanesePerBook = userData[STORAGE_KEYS.DISPLAYING_JAPANESE_PER_BOOK] || {};
    displayingJapanesePerBook[bookId] = isShow;
    userData[STORAGE_KEYS.DISPLAYING_JAPANESE_PER_BOOK] = displayingJapanesePerBook;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// 教材ごとの最後に再生した音声 ID
export const getLatestPlayedAudioResourceIdPerBookFromStorage = (firebaseUid, bookId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const audioResourceIdPerBook = userData[STORAGE_KEYS.LATEST_PLAYED_AUDIO_RESOURCE_ID_PER_BOOK] || {};
    return audioResourceIdPerBook[bookId];
};
export const setLatestPlayedAudioResourceIdPerBookFromStorage = (firebaseUid, bookId, audioResourceId) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    const audioResourceIdPerBook = userData[STORAGE_KEYS.LATEST_PLAYED_AUDIO_RESOURCE_ID_PER_BOOK] || {};
    audioResourceIdPerBook[bookId] = audioResourceId;
    userData[STORAGE_KEYS.LATEST_PLAYED_AUDIO_RESOURCE_ID_PER_BOOK] = audioResourceIdPerBook;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
// コンポーネントごとの lazyWithRetry の成否フラグ (ユーザーは関係ないので firebaseUid は不要)
export const getIsRefreshedComponentFromStorage = (importComponentFunctionBase64String) => {
    const flags = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.COMPONENT_REFRESH_FLAGS) || '{}');
    const isRefreshed = flags[importComponentFunctionBase64String] === 'true';
    return isRefreshed;
};
export const setIsRefreshedComponentStorage = (importComponentFunctionBase64String, isRefreshed) => {
    const flags = JSON.parse(sessionStorage.getItem(STORAGE_KEYS.COMPONENT_REFRESH_FLAGS) || '{}');
    flags[importComponentFunctionBase64String] = `${isRefreshed}`;
    sessionStorage.setItem(STORAGE_KEYS.COMPONENT_REFRESH_FLAGS, JSON.stringify(flags));
};
// mikan QT の設定
export const getQtSettingsFromStorage = (firebaseUid) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    return userData[STORAGE_KEYS.QT_SETTINGS];
};
export const setQtSettingsToStorage = (firebaseUid, qtSettings) => {
    const userData = JSON.parse(localStorage.getItem(firebaseUid) || '{}');
    userData[STORAGE_KEYS.QT_SETTINGS] = qtSettings;
    localStorage.setItem(firebaseUid, JSON.stringify(userData));
};
