import { useMemo } from 'react';
const LOGIN_INIT = 'LOGIN_INIT';
const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
const LOGIN_FAILURE = 'LOGIN_FAILURE';
const LOGOUT = 'LOGOUT';
const UPDATE_USER = 'UPDATE_USER';
const UPDATE_FIREBASE_USER = 'UPDATE_FIREBASE_USER';
const UPDATE_KINGDOM_MIGRATION_INFO = 'UPDATE_KINGDOM_MIGRATION_INFO';
export const authActions = {
    loginInitAction: () => {
        return { type: LOGIN_INIT };
    },
    loginSuccessAction: (payload) => {
        return { type: LOGIN_SUCCESS, payload };
    },
    loginFailureAction: (error) => {
        return { type: LOGIN_FAILURE, error };
    },
    logoutAction: () => {
        return { type: LOGOUT };
    },
    updateUserAction: (user) => {
        return { type: UPDATE_USER, user };
    },
    updateFirebaseUserAction: (firebaseUser) => {
        return { type: UPDATE_FIREBASE_USER, firebaseUser };
    },
    updateKingdomMigrationInfoAction: (kingdomMigrationInfo) => {
        return { type: UPDATE_KINGDOM_MIGRATION_INFO, kingdomMigrationInfo };
    },
};
export const initialState = {
    isLoggedIn: false,
    firebaseUser: null,
    user: null,
    school: null,
    kingdomMigrationInfo: null,
    isLoading: false,
    error: null,
};
export const authReducer = (state, action) => {
    var _a, _b, _c;
    switch (action.type) {
        case LOGIN_INIT:
            return Object.assign(Object.assign({}, state), { isLoading: true, error: null });
        case LOGIN_SUCCESS:
            return Object.assign(Object.assign({}, state), { isLoggedIn: true, isLoading: false, error: null, firebaseUser: action.payload.firebaseUser, user: action.payload.user, school: (_a = action.payload.school) !== null && _a !== void 0 ? _a : null, kingdomMigrationInfo: (_b = action.payload.kingdomMigrationInfo) !== null && _b !== void 0 ? _b : null });
        case LOGIN_FAILURE:
            return Object.assign(Object.assign({}, state), { isLoading: false, error: (_c = action.error) !== null && _c !== void 0 ? _c : null });
        case LOGOUT:
            return Object.assign(Object.assign({}, state), { isLoggedIn: false, isLoading: false, error: null, user: null, school: null, firebaseUser: null, kingdomMigrationInfo: null });
        case UPDATE_USER:
            return Object.assign(Object.assign({}, state), { user: action.user });
        case UPDATE_FIREBASE_USER:
            return Object.assign(Object.assign({}, state), { firebaseUser: action.firebaseUser });
        case UPDATE_KINGDOM_MIGRATION_INFO:
            return Object.assign(Object.assign({}, state), { kingdomMigrationInfo: action.kingdomMigrationInfo });
        default:
            return state;
    }
};
export const useAuthSelector = (state) => {
    const { error } = state;
    const errorMessage = useMemo(() => {
        if (!error) {
            return '';
        }
        // TODO: エラーメッセージの出し分け
        return '予期せぬエラーが発生しました。';
    }, [error]);
    return {
        errorMessage,
    };
};
