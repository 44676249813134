var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { HTTP_METHODS } from '../../constants/http';
import { API_HEADER_KEYS, API_PATHS } from '../../constants/mikan-rails-api';
import { NetworkError } from '../../errors/network-error';
import { UnknownError } from '../../errors/unknown-error';
import School from '../../models/school';
import mikanRailsApi from '../../utils/mikan-rails-api';
export const fetchUserSchools = (firebaseUid) => __awaiter(void 0, void 0, void 0, function* () {
    if (!firebaseUid) {
        throw new UnknownError();
    }
    const res = yield mikanRailsApi.request({
        method: HTTP_METHODS.GET,
        url: API_PATHS.USER_SCHOOLS,
        headers: { [API_HEADER_KEYS.X_FIREBASE_UID]: firebaseUid },
    });
    if (!res.data) {
        throw new NetworkError();
    }
    if (!res.data.schools && !Array.isArray(res.data.schools)) {
        return [];
    }
    const schools = res.data.schools;
    return schools.map((schoolData) => School.createFromJSON(schoolData)).filter((s) => !!s);
});
