import { lightTheme } from './palette';
const palette = lightTheme;
const colors = {
    background: {
        canvas: palette.white,
        disabled: palette.gray[300],
        surface: palette.gray[100],
        info: {
            default: palette.blue[600],
            hover: palette.blue[700],
            press: palette.blue[800],
            selected: palette.blue[600],
            subtle: palette.blue[200],
            subtleHover: palette.blue[300],
            subtlePress: palette.blue[300],
            subtleSelected: palette.blue[600],
        },
        negative: {
            default: palette.red[700],
            hover: palette.red[800],
            press: palette.red[900],
            selected: palette.red[700],
            subtle: palette.red[200],
            subtleHover: palette.red[300],
            subtlePress: palette.red[300],
            subtleSelected: palette.red[700],
        },
        neutral: {
            default: palette.gray[1000],
            hover: palette.gray[900],
            press: palette.gray[800],
            selected: palette.gray[1000],
            subtle: palette.blackAlpha[200],
            subtleHover: palette.blackAlpha[300],
            subtlePress: palette.blackAlpha[400],
            subtleSelected: palette.gray[1000],
        },
        overlay: {
            default: palette.blackAlpha[600],
            hover: palette.blackAlpha[700],
            press: palette.blackAlpha[800],
            selected: palette.whiteAlpha[600],
        },
        positive: {
            default: palette.green[600],
            hover: palette.green[700],
            press: palette.green[800],
            selected: palette.green[600],
            subtle: palette.green[200],
            subtleHover: palette.green[300],
            subtlePress: palette.green[300],
            subtleSelected: palette.green[600],
        },
        primary: {
            default: palette.orange[600],
            hover: palette.orange[700],
            press: palette.orange[800],
            selected: palette.orange[600],
            subtle: palette.orange[200],
            subtleHover: palette.orange[300],
            subtlePress: palette.orange[300],
            subtleSelected: palette.orange[600],
        },
        base: {
            default: palette.white,
            hover: palette.gray[200],
            press: palette.gray[300],
            selected: palette.white,
        },
    },
    border: {
        main: palette.gray[200],
        onFill: palette.white,
        primary: palette.orange[600],
        focus: {
            infoLight: palette.blue[400],
            negativeLight: palette.red[400],
            neutralLight: palette.gray[300],
            positiveLight: palette.green[400],
            primaryLight: palette.orange[400],
        },
    },
    learningLevel: {
        bad: palette.blue[600],
        excellent: palette.green[800],
        good: palette.lightGreen[500],
        great: palette.green[600],
        notYet: palette.gray[400],
    },
    shadow: {
        light: palette.blackAlpha[100],
        medium: palette.blackAlpha[200],
        strong: palette.blackAlpha[300],
    },
    content: {
        disabled: palette.gray[300],
        hint: palette.gray[400],
        info: palette.blue[700],
        infoStrong: palette.blue[1000],
        main: palette.gray[1000],
        negative: palette.red[600],
        negativeStrong: palette.red[900],
        onContrast: palette.white,
        positive: palette.green[600],
        positiveStrong: palette.green[900],
        primary: palette.orange[700],
        primaryStrong: palette.orange[1000],
        sub: palette.gray[600],
    },
};
export default colors;
