// production configs: overrides base configs
const config = {
    MIKAN_RAILS_API_ENDPOINT: 'https://api.mikan.link',
    MIKAN_BFF_ENDPOINT: 'https://prd-api.mikan.link',
    FIREBASE_API_KEY: 'AIzaSyAqyUa2V9zTgPPTcaETOXNC1PVBThD14Ok',
    FIREBASE_AUTH_DOMAIN: 'mikan-prd.firebaseapp.com',
    FIREBASE_DATABASE_URL: 'https://mikan-prd.firebaseio.com',
    FIREBASE_PROJECT_ID: 'mikan-prd',
    FIREBASE_STORAGE_BUCKET: 'mikan-prd.appspot.com',
    FIREBASE_MESSAGING_SENDER_ID: '521537403122',
    FIREBASE_APP_ID: '1:521537403122:web:e2693f1f8f60db2826386b',
    FIREBASE_MEASUREMENT_ID: 'G-ZGBW3FGXYV',
    FIREBASE_APP_CHECK_DEBUG: false,
    FIREHOSE_IDENTITY_POOL_ID: 'ap-northeast-1:2f47a076-7292-4aba-bc41-0df807ed4a12',
    FIREHOSE_DELIVERY_STREAM_NAME: 'mikan-logs-streams',
};
export default config;
