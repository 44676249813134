import { EVALUATIONS, EXAM_STATUSES } from './firestore';
const PUBLIC_IMAGE_DIR = '/public/images';
const PUBLIC_SOUND_DIR = '/public/sounds';
const PUBLIC_FONT_DIR = '/public/fonts';
export const IMAGE_PATHS = {
    AUDIO_DETAIL_BACK: `${PUBLIC_IMAGE_DIR}/audio-detail-back.svg`,
    BACK: `${PUBLIC_IMAGE_DIR}/back.svg`,
    BOOK_BACK: `${PUBLIC_IMAGE_DIR}/book-back.svg`,
    CARD_SWIPE_LEFT: `${PUBLIC_IMAGE_DIR}/card-swipe-left.png`,
    CARD_SWIPE_RIGHT: `${PUBLIC_IMAGE_DIR}/card-swipe-right.png`,
    CHECKBOX: `${PUBLIC_IMAGE_DIR}/checkbox.svg`,
    CHECKBOX_EMPTY: `${PUBLIC_IMAGE_DIR}/checkbox-empty.svg`,
    CLOSE_ICON: `${PUBLIC_IMAGE_DIR}/close-icon.svg`,
    COPY: `${PUBLIC_IMAGE_DIR}/copy.svg`,
    COPY_DONE: `${PUBLIC_IMAGE_DIR}/copy-done.svg`,
    CORRECT: `${PUBLIC_IMAGE_DIR}/correct.png`,
    CROSS_GRAY: `${PUBLIC_IMAGE_DIR}/cross-gray.png`,
    FINISH_LEARNING: `${PUBLIC_IMAGE_DIR}/finish-learning.png`,
    ICON_WINK: `${PUBLIC_IMAGE_DIR}/icon-wink.png`,
    INFO: `${PUBLIC_IMAGE_DIR}/info.svg`,
    INQUIRY: `${PUBLIC_IMAGE_DIR}/inquiry.svg`,
    LIST_SELECT: `${PUBLIC_IMAGE_DIR}/list-select.svg`,
    LIST_SORT: `${PUBLIC_IMAGE_DIR}/list-sort.svg`,
    LOGIN_ILLUST: `${PUBLIC_IMAGE_DIR}/login-illust.svg`,
    LOGIN_ILLUST_SP: `${PUBLIC_IMAGE_DIR}/login-illust-sp.svg`,
    MIKAN_OJIGI: `${PUBLIC_IMAGE_DIR}/mikan-ojigi.png`,
    MINUS_BUTTON: `${PUBLIC_IMAGE_DIR}/minus-button.svg`,
    MUKEO: `${PUBLIC_IMAGE_DIR}/mukeo.svg`,
    MUKEO_EXAM_START: `${PUBLIC_IMAGE_DIR}/mukeo-exam-start.svg`,
    MUKEO_EXAM_FINISH: `${PUBLIC_IMAGE_DIR}/mukeo-exam-finish.svg`,
    MUKEO_STATIC: `${PUBLIC_IMAGE_DIR}/mukeo-static.svg`,
    NO: `${PUBLIC_IMAGE_DIR}/no.svg`,
    PASSWORD_REVEAL: `${PUBLIC_IMAGE_DIR}/password-reveal.svg`,
    PLUS_BUTTON: `${PUBLIC_IMAGE_DIR}/plus-button.svg`,
    RANKING_ICON: `${PUBLIC_IMAGE_DIR}/ranking-icon.svg`,
    READER_ICON: `${PUBLIC_IMAGE_DIR}/reader-icon.svg`,
    REPORT_ICON: `${PUBLIC_IMAGE_DIR}/report-icon.svg`,
    RIGHT_INDICATOR: `${PUBLIC_IMAGE_DIR}/right-indicator.svg`,
    SELECT_ARROW_DOWNWARD: `${PUBLIC_IMAGE_DIR}/select-arrow-downward.png`,
    SELECT_SHEET_CHECK: `${PUBLIC_IMAGE_DIR}/select-sheet-check.png`,
    SKIP: `${PUBLIC_IMAGE_DIR}/skip.png`,
    SKIP_BUTTON_ICON: `${PUBLIC_IMAGE_DIR}/skip-button-icon.svg`,
    SPEAKER: `${PUBLIC_IMAGE_DIR}/speaker.svg`,
    SPEAKING_BUTTON: `${PUBLIC_IMAGE_DIR}/speaking-button.svg`,
    STOP_WATCH: `${PUBLIC_IMAGE_DIR}/stop-watch.png`,
    STUDIED_RECORDS_CALENDAR_ICON: `${PUBLIC_IMAGE_DIR}/studied-records-calendar-icon.svg`,
    STUDIED_RECORDS_PENSIL_ICON: `${PUBLIC_IMAGE_DIR}/studied-records-pensil-icon.svg`,
    TEXT_LOGO: `${PUBLIC_IMAGE_DIR}/text-logo.svg`,
    USER_SPEAKING: `${PUBLIC_IMAGE_DIR}/user-speaking.svg`,
    USER_TYPING: `${PUBLIC_IMAGE_DIR}/user-typing.svg`,
    YES: `${PUBLIC_IMAGE_DIR}/yes.svg`,
    WORD_DETAIL_ICON: `${PUBLIC_IMAGE_DIR}/word-detail-icon.svg`,
};
export const ASSIGNMENT_ICON_PATHS = {
    AUDIO: `${PUBLIC_IMAGE_DIR}/assignment/audio.svg`,
    CARD: `${PUBLIC_IMAGE_DIR}/assignment/card.svg`,
    CIRCLE_DONE: `${PUBLIC_IMAGE_DIR}/assignment/circle-done.svg`,
    HOMEWORK: `${PUBLIC_IMAGE_DIR}/assignment/homework.svg`,
    HOMEWORK_DONE: `${PUBLIC_IMAGE_DIR}/assignment/homework-done.svg`,
    HOMEWORK_GRAY: `${PUBLIC_IMAGE_DIR}/assignment/homework-gray.svg`,
    HOMEWORK_NOT_STARTED: `${PUBLIC_IMAGE_DIR}/assignment/homework-not-started.svg`,
    TEST: `${PUBLIC_IMAGE_DIR}/assignment/test.svg`,
};
export const EVALUATION_IMAGE_PATHS = {
    [EVALUATIONS.EXCELLENT]: `${PUBLIC_IMAGE_DIR}/excellent.png`,
    [EVALUATIONS.GREAT]: `${PUBLIC_IMAGE_DIR}/great.png`,
    [EVALUATIONS.GOOD]: `${PUBLIC_IMAGE_DIR}/good.png`,
    [EVALUATIONS.BAD]: `${PUBLIC_IMAGE_DIR}/bad.png`,
};
export const CORRECT_IMAGE_PATHS = {
    [EVALUATIONS.EXCELLENT]: `${PUBLIC_IMAGE_DIR}/correct.png`,
    [EVALUATIONS.GREAT]: `${PUBLIC_IMAGE_DIR}/correct.png`,
    [EVALUATIONS.GOOD]: `${PUBLIC_IMAGE_DIR}/correct.png`,
    [EVALUATIONS.BAD]: `${PUBLIC_IMAGE_DIR}/incorrect.png`,
};
export const EXAM_STATUSES_IMAGE_PATHS = {
    [EXAM_STATUSES.PERFECT]: `${PUBLIC_IMAGE_DIR}/exam-perfect.png`,
    [EXAM_STATUSES.GREAT]: `${PUBLIC_IMAGE_DIR}/exam-great.png`,
    [EXAM_STATUSES.WIP]: `${PUBLIC_IMAGE_DIR}/exam-wip.png`,
    [EXAM_STATUSES.NOT_YET]: `${PUBLIC_IMAGE_DIR}/exam-notyet.png`,
    [EXAM_STATUSES.ASSIGNMENT_NOT_STARTED]: ASSIGNMENT_ICON_PATHS.HOMEWORK_NOT_STARTED,
    [EXAM_STATUSES.ASSIGNMENT_COMPLETED]: ASSIGNMENT_ICON_PATHS.HOMEWORK_DONE,
};
export const CORRECT_RATE_IMAGE_PATHS = {
    0: `${PUBLIC_IMAGE_DIR}/correct-rate-0.png`,
    1: `${PUBLIC_IMAGE_DIR}/correct-rate-12.png`,
    2: `${PUBLIC_IMAGE_DIR}/correct-rate-12.png`,
    3: `${PUBLIC_IMAGE_DIR}/correct-rate-35.png`,
    4: `${PUBLIC_IMAGE_DIR}/correct-rate-35.png`,
    5: `${PUBLIC_IMAGE_DIR}/correct-rate-35.png`,
    6: `${PUBLIC_IMAGE_DIR}/correct-rate-68.png`,
    7: `${PUBLIC_IMAGE_DIR}/correct-rate-68.png`,
    8: `${PUBLIC_IMAGE_DIR}/correct-rate-68.png`,
    9: `${PUBLIC_IMAGE_DIR}/correct-rate-910.png`,
    10: `${PUBLIC_IMAGE_DIR}/correct-rate-910.png`,
};
export const SOUND_PATHS = {
    CORRECT: `${PUBLIC_SOUND_DIR}/correct.mp3`,
    HELLO: `${PUBLIC_SOUND_DIR}/hello.mp3`,
    INCORRECT: `${PUBLIC_SOUND_DIR}/incorrect.mp3`,
    SILENCE: `${PUBLIC_SOUND_DIR}/silence.wav`,
    SOUND_TEST_RESULT: `${PUBLIC_SOUND_DIR}/sound-test-result.mp3`,
    SOUND_TEST_WELL: `${PUBLIC_SOUND_DIR}/sound-test-well.mp3`,
    SOUND_TEST_PERFECT: `${PUBLIC_SOUND_DIR}/sound-test-perfect.mp3`,
};
export const PROFILE_ICON_PATHS = {
    'default_1.png': `${PUBLIC_IMAGE_DIR}/profile-icons/default_1.png`,
    'default_2.png': `${PUBLIC_IMAGE_DIR}/profile-icons/default_2.png`,
    'default_3.png': `${PUBLIC_IMAGE_DIR}/profile-icons/default_3.png`,
    'default_4.png': `${PUBLIC_IMAGE_DIR}/profile-icons/default_4.png`,
};
export const CHARACTER_ICON_PATHS = {
    'default_1.png': `${PUBLIC_IMAGE_DIR}/character-icons/default_1.png`,
    'default_2.png': `${PUBLIC_IMAGE_DIR}/character-icons/default_2.png`,
    'default_3.png': `${PUBLIC_IMAGE_DIR}/character-icons/default_3.png`,
    'default_4.png': `${PUBLIC_IMAGE_DIR}/character-icons/default_4.png`,
    'default_5.png': `${PUBLIC_IMAGE_DIR}/character-icons/default_5.png`,
    'default_6.png': `${PUBLIC_IMAGE_DIR}/character-icons/default_6.png`,
    'default_7.png': `${PUBLIC_IMAGE_DIR}/character-icons/default_7.png`,
    'default_8.png': `${PUBLIC_IMAGE_DIR}/character-icons/default_8.png`,
    'default_9.png': `${PUBLIC_IMAGE_DIR}/character-icons/default_9.png`,
};
export const LEARNING_SETTINGS_ICON_PATHS = {
    LEARNING_CARD: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-card.svg`,
    LEARNING_CARD_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-card-selected.svg`,
    LEARNING_CHOICES: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-choices.svg`,
    LEARNING_CHOICES_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-choices-selected.svg`,
    LEARNING_EXAMPLE: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-example.svg`,
    LEARNING_EXAMPLE_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-example-selected.svg`,
    LEARNING_FORMAT_EJ: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-ej.svg`,
    LEARNING_FORMAT_EJ_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-ej-selected.svg`,
    LEARNING_FORMAT_JE: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-je.svg`,
    LEARNING_FORMAT_JE_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-je-selected.svg`,
    LEARNING_FORMAT_LISTENING: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-listening.svg`,
    LEARNING_FORMAT_LISTENING_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-listening-selected.svg`,
    LEARNING_FORMAT_QUICK_SPEAKING: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-quick-speaking.svg`,
    LEARNING_FORMAT_QUICK_SPEAKING_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-quick-speaking-selected.svg`,
    LEARNING_FORMAT_REPEATING: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-repeating.svg`,
    LEARNING_FORMAT_REPEATING_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-repeating-selected.svg`,
    LEARNING_FORMAT_SHADOWING: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-shadowing.svg`,
    LEARNING_FORMAT_SHADOWING_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-format-shadowing-selected.svg`,
    LEARNING_OPTION_OFF: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-option-off.svg`,
    LEARNING_OPTION_ON: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-option-on.svg`,
    LEARNING_SPEAKING: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-speaking.svg`,
    LEARNING_SPEAKING_SELECTED: `${PUBLIC_IMAGE_DIR}/learning-settings/learning-speaking-selected.svg`,
    PRO_LOCK: `${PUBLIC_IMAGE_DIR}/learning-settings/pro-lock.svg`,
    PROPLUS_LOCK: `${PUBLIC_IMAGE_DIR}/learning-settings/proplus-lock.svg`,
    STUDY_MODE_CARD: `${PUBLIC_IMAGE_DIR}/learning-settings/study-mode-card.svg`,
    STUDY_MODE_CHOICES: `${PUBLIC_IMAGE_DIR}/learning-settings/study-mode-choices.svg`,
    STUDY_MODE_SENTENCE: `${PUBLIC_IMAGE_DIR}/learning-settings/study-mode-sentence.svg`,
    STUDY_MODE_SPEAKING: `${PUBLIC_IMAGE_DIR}/learning-settings/study-mode-speaking.svg`,
    STUDY_MODE_TYPING: `${PUBLIC_IMAGE_DIR}/learning-settings/study-mode-typing.svg`,
    TEST_TYPE_DICTATION: `${PUBLIC_IMAGE_DIR}/learning-settings/test-type-dictation.svg`,
    TEST_TYPE_EN_JP: `${PUBLIC_IMAGE_DIR}/learning-settings/test-type-en-jp.svg`,
    TEST_TYPE_JP_EN: `${PUBLIC_IMAGE_DIR}/learning-settings/test-type-jp-en.svg`,
    TEST_TYPE_LISTENING: `${PUBLIC_IMAGE_DIR}/learning-settings/test-type-listening.svg`,
    TEST_TYPE_FOR_SPEAKING_REPEATING: `${PUBLIC_IMAGE_DIR}/learning-settings/test-type-for-speaking-repeating.svg`,
    TEST_TYPE_FOR_SPEAKING_QUICK_SPEAKING: `${PUBLIC_IMAGE_DIR}/learning-settings/test-type-for-speaking-quick-speaking.svg`,
    TEST_TYPE_FOR_SPEAKING_SHADOWING: `${PUBLIC_IMAGE_DIR}/learning-settings/test-type-for-speaking-shadowing.svg`,
    VOLUME_LESS: `${PUBLIC_IMAGE_DIR}/learning-settings/volume-less.svg`,
    VOLUME_UP: `${PUBLIC_IMAGE_DIR}/learning-settings/volume-up.svg`,
};
export const AUDIO_PLAYER_ICON_PATHS = {
    AUDIO_BACKWARD: `${PUBLIC_IMAGE_DIR}/audio-player/audio-backward.svg`,
    AUDIO_FORWARD: `${PUBLIC_IMAGE_DIR}/audio-player/audio-forward.svg`,
    AUDIO_NEXT: `${PUBLIC_IMAGE_DIR}/audio-player/audio-next.svg`,
    AUDIO_PAUSE: `${PUBLIC_IMAGE_DIR}/audio-player/audio-pause.svg`,
    AUDIO_PLAY: `${PUBLIC_IMAGE_DIR}/audio-player/audio-play.svg`,
    AUDIO_PLAYER_ICON: `${PUBLIC_IMAGE_DIR}/audio-player/audio-player-icon.svg`,
    AUDIO_PREV: `${PUBLIC_IMAGE_DIR}/audio-player/audio-prev.svg`,
};
export const BOOK_TAB_ICON_PATHS = {
    BOOK_TAB_AUDIO: `${PUBLIC_IMAGE_DIR}/book-tab/book-tab-audio.svg`,
    BOOK_TAB_AUDIO_SELECTED: `${PUBLIC_IMAGE_DIR}/book-tab/book-tab-audio-selected.svg`,
    BOOK_TAB_LEARNING: `${PUBLIC_IMAGE_DIR}/book-tab/book-tab-learning.svg`,
    BOOK_TAB_LEARNING_SELECTED: `${PUBLIC_IMAGE_DIR}/book-tab/book-tab-learning-selected.svg`,
    BOOK_TAB_READER: `${PUBLIC_IMAGE_DIR}/book-tab/book-tab-reader.svg`,
    BOOK_TAB_READER_SELECTED: `${PUBLIC_IMAGE_DIR}/book-tab/book-tab-reader-selected.svg`,
    BOOK_TAB_QUESTIONS: `${PUBLIC_IMAGE_DIR}/book-tab/book-tab-questions.svg`,
    BOOK_TAB_QUESTIONS_SELECTED: `${PUBLIC_IMAGE_DIR}/book-tab/book-tab-questions-selected.svg`,
};
export const FONT_PATHS = {
    TEXT_SECURITY_DISC: `${PUBLIC_FONT_DIR}/text-security-disc.ttf`,
};
