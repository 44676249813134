// プロパティは必要になったら追加する
class User {
    constructor(userId, name, firstName, lastName, iconName, howToUseMenuUrl, howToUseMenuText, isSchoolAssignmentActivated, rooms, school) {
        this.userId = userId;
        this.name = name;
        this.firstName = firstName;
        this.lastName = lastName;
        this.iconName = iconName;
        this.howToUseMenuUrl = howToUseMenuUrl.length > 0 ? howToUseMenuUrl : null;
        this.howToUseMenuText = howToUseMenuText.length > 0 ? howToUseMenuText : null;
        this.isSchoolAssignmentActivated = isSchoolAssignmentActivated;
        this.rooms = rooms;
        this.school = school;
    }
    get fullName() {
        if (!this.firstName && !this.lastName) {
            return '';
        }
        return `${this.lastName} ${this.firstName}`;
    }
}
User.createFromJSON = (json) => {
    var _a, _b, _c, _d, _e;
    if (!json) {
        return;
    }
    let school;
    if (json.school) {
        school = { id: json.school.school_id || '', name: json.school.name || '' };
    }
    const jsonRooms = json.rooms || [];
    let rooms = [];
    if (Array.isArray(jsonRooms)) {
        rooms = jsonRooms.map((val) => ({ id: val.id, name: val.name, roomCode: val.room_code }));
    }
    return new User(json.user_id, (_a = json.name) !== null && _a !== void 0 ? _a : '', (_b = json.first_name) !== null && _b !== void 0 ? _b : '', (_c = json.last_name) !== null && _c !== void 0 ? _c : '', json.icon_name, (_d = json.how_to_use_url) !== null && _d !== void 0 ? _d : '', (_e = json.how_to_use_menu_text) !== null && _e !== void 0 ? _e : '', json.is_school_assignment_activated, rooms, school);
};
export default User;
