import qs from 'query-string';
import { ROUTE_DEFINITION } from '../../routing/constants';
export const urlgen = (key, ...args) => {
    const [payload, queryParams = {}, options = {}] = args;
    const opts = Object.assign({ encodeQuery: true }, options);
    const found = ROUTE_DEFINITION[key];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const path = typeof found === 'function' ? found(payload) : found;
    const q = qs.stringify(queryParams, { encode: opts.encodeQuery });
    const search = q && `?${q}`;
    const fragment = opts.fragment && `#${opts.fragment}`;
    return [path, search, fragment].join('');
};
