const globalStyles = (_props) => ({
    html: {
        backgroundColor: 'white',
        fontFamily: `-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, 'Apple Color Emoji', Arial, sans-serif, 'Segoe UI Emoji', 'Segoe UI Symbol'`,
        fontSize: '62.5%',
        MsTextSizeAdjust: '100%',
        WebkitAextSizeAdjust: '100%',
        WebkitFontSmoothing: 'auto',
    },
    body: {
        fontSize: '1.6rem',
        color: 'rgba(34, 34, 34, 0.9)',
    },
    p: {
        userSelect: 'auto',
        WebkitUserSelect: 'none',
    },
    button: {
        border: 'none',
    },
});
export const styles = {
    global: globalStyles,
};
