import { AuthErrorCodes } from 'firebase/auth';
import { FIRESTORE_ERROR_CODES } from '../constants/firestore';
import { BaseError } from './base-error';
export class FirebaseError extends BaseError {
    constructor(firebaseError) {
        let message = '予期せぬエラーが発生しました';
        switch (firebaseError.code) {
            // Auth 関連のエラー
            case AuthErrorCodes.INVALID_CUSTOM_TOKEN:
                // signInWithCustomToken でのトークンが無効 (firebase の環境がバックエンドの環境と合っていない可能性がある)
                message = `無効なトークンにより認証に失敗しました\nこのメッセージが表示された場合はお問い合わせください`;
                break;
            case AuthErrorCodes.OPERATION_NOT_ALLOWED:
                // 匿名ログインが有効になっていない
                message = `この環境ではログインが無効です\nこのメッセージが表示された場合はお問い合わせください`;
                break;
            // Firestore 関連のエラー
            case FIRESTORE_ERROR_CODES['not-found']:
                message = `データが存在しません`;
                break;
            case FIRESTORE_ERROR_CODES['already-exists']:
                message = `既にデータが存在しているため、データの処理に失敗しました`;
                break;
            case FIRESTORE_ERROR_CODES['permission-denied']:
                message = `許可されていない操作です`;
                break;
            case FIRESTORE_ERROR_CODES.unauthenticated:
                message = `認証されていないため処理を中止しました\n一度ログアウトして再度ログインをお試しください`;
                break;
            case FIRESTORE_ERROR_CODES['invalid-argument']:
                message = `パラメータが不正、もしくはデータの処理件数が多すぎるため処理を中止しました\nこのメッセージが表示された場合はお問い合わせください`;
                break;
            default:
                break;
        }
        super(message);
        this.name = new.target.name;
        this.firebaseError = firebaseError;
    }
}
