var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useCallback, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { config } from '../config';
import { IMAGE_PATHS } from '../constants/filepath';
import { KingdomMigrationStatus } from '../gql/generated/graphql';
export const KingdomMigrationStatusContent = ({ status, schoolUserId, signOutAndGotoLogin }) => {
    const [isCopiedSchoolUserIdentifier, setIsCopiedSchoolUserIdentifier] = useState(false);
    const copySchoolUserIdentifier = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        if (schoolUserId == null) {
            return;
        }
        try {
            yield navigator.clipboard.writeText(schoolUserId);
            setIsCopiedSchoolUserIdentifier(true);
        }
        catch (_a) { }
    }), [schoolUserId]);
    return (_jsxs(Box, { sx: containerStyle, children: [_jsxs("div", { className: "main-content", children: [_jsx("img", { className: "ojigi-icon", src: IMAGE_PATHS.MIKAN_OJIGI }), status === KingdomMigrationStatus.Executing && (_jsx("p", { className: "description", children: `現在データの更新作業のため\n一時的にアクセスいただけません。\nしばらくしてから再度アクセスしてください。` })), _jsx("a", { className: "inquiry", href: config.get('INQUIRY_URL'), children: "\u554F\u3044\u5408\u308F\u305B" }), _jsx("button", { className: "logout", onClick: signOutAndGotoLogin, children: "\u30ED\u30B0\u30A2\u30A6\u30C8\u3059\u308B" })] }), schoolUserId != null && (_jsxs("div", { className: "footer-content", children: [_jsxs("p", { className: "uid", children: ["ID: ", schoolUserId] }), _jsxs("button", { className: "copy-school-user-identifier", onClick: copySchoolUserIdentifier, children: [isCopiedSchoolUserIdentifier ? 'コピーしました！' : 'ID をコピーする', _jsx("img", { alt: "", src: isCopiedSchoolUserIdentifier ? IMAGE_PATHS.COPY_DONE : IMAGE_PATHS.COPY })] })] }))] }));
};
const FOOTER_HEIGHT = '6rem';
const containerStyle = {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflowY: 'scroll',
    width: '100%',
    'div.main-content': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        gap: '2rem',
        height: `calc(100vh - ${FOOTER_HEIGHT})`,
        justifyContent: 'center',
        minHeight: '50rem',
        padding: '0 1rem',
        'img.ojigi-icon': {
            width: '20rem',
            objectFit: 'cover',
        },
        'p.description': {
            textAlign: 'center',
            whiteSpace: 'pre-wrap',
            fontSize: '1.8rem',
            lineHeight: '2.8rem',
        },
        'a.inquiry': {
            color: 'blue',
            textDecoration: 'none',
        },
        'button.logout': {
            color: 'rgba(255, 59, 48)',
        },
    },
    'div.footer-content': {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: `${FOOTER_HEIGHT}`,
        justifyContent: 'space-around',
        margin: '2rem 0',
        'p.uid': {
            color: 'rgba(34, 34, 34, 0.3)',
            margin: 0,
        },
        'button.copy-school-user-identifier': {
            alignItems: 'center',
            color: 'rgba(34, 34, 34, 0.3)',
            display: 'flex',
            fontSize: '1.4rem',
            gap: '0.5rem',
            img: {
                width: '1.6rem',
            },
        },
    },
};
