// base configs: defaults for all environments
const config = {
    APP_ENV: process.env.APP_ENV || 'unknown',
    MIKAN_RAILS_API_ENDPOINT: process.env.MIKAN_RAILS_API_ENDPOINT || '',
    MIKAN_BFF_ENDPOINT: process.env.MIKAN_BFF_ENDPOINT || '',
    FIREBASE_API_KEY: process.env.FIREBASE_API_KEY || '',
    FIREBASE_AUTH_DOMAIN: process.env.FIREBASE_AUTH_DOMAIN || '',
    FIREBASE_DATABASE_URL: process.env.FIREBASE_DATABASE_URL || '',
    FIREBASE_PROJECT_ID: process.env.FIREBASE_PROJECT_ID || '',
    FIREBASE_STORAGE_BUCKET: process.env.FIREBASE_STORAGE_BUCKET || '',
    FIREBASE_MESSAGING_SENDER_ID: process.env.FIREBASE_MESSAGING_SENDER_ID || '',
    FIREBASE_APP_ID: process.env.FIREBASE_APP_ID || '',
    FIREBASE_MEASUREMENT_ID: process.env.FIREBASE_MEASUREMENT_ID || '',
    FIREBASE_APP_CHECK_DEBUG: process.env.FIREBASE_APP_CHECK_DEBUG === 'true' || false,
    WEBLIO_WORD_SEARCH_URL: process.env.WEBLIO_WORD_SEARCH_URL || 'https://ejje.weblio.jp/content/',
    SENTRY_ENV: process.env.SENTRY_ENV || 'production',
    SENTRY_DRY: process.env.SENTRY_DRY === 'true' || false,
    SENTRY_DSN: process.env.SENTRY_DSN || 'https://4c9b9e57b12b481db977a5a7cf84385a@o1334960.ingest.sentry.io/6601992',
    GA_STREAM_ID: process.env.GA_STREAM_ID || '3860872957',
    RE_CAPTCHA_SITE_KEY: process.env.RE_CAPTCHA_SITE_KEY || '6Ldl6R8hAAAAAHJ5-8yl6mHngXPmBYcGWVym1dmp',
    INQUIRY_URL: process.env.INQUIRY_URL ||
        'https://mikan.helpshift.com/a/%E8%8B%B1%E5%8D%98%E8%AA%9E%E3%82%A2%E3%83%95%E3%83%AA-mikan/?p=all&contact=1',
    FIREHOSE_IDENTITY_POOL_ID: process.env.FIREHOSE_IDENTITY_POOL_ID || '',
    FIREHOSE_DELIVERY_STREAM_NAME: process.env.FIREHOSE_DELIVERY_STREAM_NAME || '',
    TRANSCRIBE_IDENTITY_POOL_ID: process.env.TRANSCRIBE_IDENTITY_POOL_ID || 'ap-northeast-1:7acb83ae-f677-4846-8607-1b094ab7ab49',
    PDF_PASSWORD: process.env.PDF_PASSWORD || '}9FfTbqRF8ve4}{z7Qfyb^',
    ENABLE_KINGDOM: process.env.ENABLE_KINGDOM === 'true' || false,
    ENABLE_APP_CHECK: process.env.ENABLE_APP_CHECK === 'true' || false,
    ENABLE_CHEAT_DETECTION: process.env.ENABLE_CHEAT_DETECTION === 'true' || false,
    ENABLE_ASSIGNMENTS: process.env.ENABLE_ASSIGNMENTS === 'true' || false,
};
export default config;
