var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Howl, Howler } from 'howler';
import { SOUND_PATHS } from '../constants/filepath';
import { getSEAudioVolumeFromStorage, getWordAudioVolumeFromStorage } from './app-storage';
import { normalizeAndNotifyError } from './error';
export const audioForSE = document.getElementById('SE-audio');
export const audioForWord = document.getElementById('word-audio');
Howler.html5PoolSize = 100;
/**
 * 音声をプリロードする処理
 * @param sources 音声ファイル URL の配列
 * @returns HTMLAudioElement の配列
 */
export const prepareSoundEffect = (...sources) => {
    return sources.map((src) => {
        const audio = new Audio();
        audio.src = src;
        audio.preload = 'auto';
        return audio;
    });
};
/**
 * 音声の再生処理
 * @remarks
 *  音声の自動再生の都合上, 引数で受け取る audio 自体は使用できず,
 *
 *  再生する際は audioForSE or audioForWord の src に audio の src を代入してどちらかの方で再生する
 * @param audio 再生対象の HTMLAudioElement
 */
export const playAudio = (type, audio) => __awaiter(void 0, void 0, void 0, function* () {
    if (!audio) {
        return;
    }
    try {
        if (type === 'SE' && audioForSE) {
            audioForSE.src = audio.src;
            if (!audioForSE.paused) {
                audioForSE.pause();
                audioForSE.currentTime = 0;
            }
            yield audioForSE.play();
        }
        if (type === 'word' && audioForWord) {
            audioForWord.src = audio.src;
            if (!audioForWord.paused) {
                audioForWord.pause();
                audioForWord.currentTime = 0;
            }
            yield audioForWord.play();
        }
    }
    catch (error) {
        // 特にできることはないので, とりえあずログだけ送るようにする
        normalizeAndNotifyError(error);
    }
});
/**
 * howler.js を使用した音声の再生処理
 * @param type 効果音 (SE) or 単語 (word)
 * @param audioUrl 再生対象のソース URL
 * @param firebaseUid 音量を localStorage から取得するための firebaseUid
 */
export const playAudioWithHowl = (type, audioUrl, firebaseUid) => __awaiter(void 0, void 0, void 0, function* () {
    if (!audioUrl) {
        return;
    }
    let volume = 1.0;
    if (firebaseUid) {
        switch (type) {
            case 'SE':
                volume = getSEAudioVolumeFromStorage(firebaseUid);
                break;
            case 'word':
                volume = getWordAudioVolumeFromStorage(firebaseUid);
                break;
            default:
                break;
        }
    }
    const sound = new Howl({
        src: [audioUrl],
        html5: true,
        autoplay: true,
        volume,
    });
    sound.once('end', sound.unload);
    sound.play();
});
/**
 * 自動で音声を再生できるようにするための処理
 * @remarks
 * ユーザーのインタラクション (ボタン押下) などの直後に呼ぶこと
 *
 * HTML ファイルに最初から追加されている audioForSE & audioForWord 要素を使って音声許可を得る
 *
 * 効果音を再生させる場合は audioForSE, 発音を再生させる場合は audioForWord を使う必要がある
 */
export const prepareAutoPlay = (type, firebaseUid) => __awaiter(void 0, void 0, void 0, function* () {
    try {
        if (type === 'SE' && audioForSE) {
            audioForSE.src = '';
            audioForSE.volume = getSEAudioVolumeFromStorage(firebaseUid);
            yield audioForSE.play();
            audioForSE.pause();
        }
        if (type === 'word' && audioForWord) {
            audioForWord.src = '';
            audioForWord.volume = getWordAudioVolumeFromStorage(firebaseUid);
            yield audioForWord.play();
            audioForWord.pause();
        }
    }
    catch (_a) { }
});
/**
 * Howl で音声を再生する場合に、自動で音声を再生できるようにするための処理
 * @remark
 * 音声再生前に呼び出すとうまく動作しないので、画面遷移時などの早めのタイミングで呼ぶこと
 * REF: https://github.com/goldfire/howler.js?tab=readme-ov-file#mobilechrome-playback
 */
export const prepareAutoPlayForHowl = () => __awaiter(void 0, void 0, void 0, function* () {
    try {
        const newSound = new Howl({
            src: [SOUND_PATHS.SILENCE],
            onplayerror: () => {
                newSound.once('unlock', () => {
                    newSound.play();
                });
            },
        });
        newSound.play();
    }
    catch (_a) { }
});
/**
 * テストや学習結果に応じた音声の再生
 * @param correctRate 正答率 (0 ~ 1)
 */
export const playTestResultAudio = (correctRate) => {
    let resultAudio;
    if (1 <= correctRate) {
        const [audio] = prepareSoundEffect(SOUND_PATHS.SOUND_TEST_PERFECT);
        resultAudio = audio;
    }
    else if (0.5 < correctRate && correctRate < 1) {
        const [audio] = prepareSoundEffect(SOUND_PATHS.SOUND_TEST_WELL);
        resultAudio = audio;
    }
    else {
        const [audio] = prepareSoundEffect(SOUND_PATHS.SOUND_TEST_RESULT);
        resultAudio = audio;
    }
    playAudio('SE', resultAudio);
};
/**
 * 効果音の音量設定
 * @remarks
 * 基本的に app-storage の setSEAudioVolumeToStorage メソッドでのみ呼び出す
 *
 * @param volume 音量 (0.00 ~ 1.00)
 */
export const changeSEAudioVolume = (volume) => {
    if (!audioForSE) {
        return;
    }
    audioForSE.volume = volume;
};
/**
 * 発音の音量設定
 * @remarks
 * 基本的に app-storage の setWordAudioVolumeToStorage メソッドでのみ呼び出す
 *
 * @param volume 音量 (0.00 ~ 1.00)
 */
export const changeWordAudioVolume = (volume) => {
    if (!audioForWord) {
        return;
    }
    audioForWord.volume = volume;
};
