import stringify from 'safe-json-stringify';
import { FirebaseError as FirebaseAppError } from 'firebase/app';
import { FirebaseError } from '../errors/firebase-error-wrapper';
import { UnknownError } from '../errors/unknown-error';
import { notifyError } from './errorReporting';
export const normalizeError = (error) => {
    if (error instanceof FirebaseAppError) {
        return new FirebaseError(error);
    }
    if (error instanceof Error) {
        return error;
    }
    return new UnknownError();
};
/** @deprecated use `normalizeError()` and `notifyError()` */
export const normalizeAndNotifyError = (error) => {
    const err = normalizeError(error);
    notifyError(err);
    return err;
};
export class CustomError extends Error {
    constructor(name, error) {
        let message;
        if (error instanceof Error) {
            message = error.message;
        }
        else {
            switch (typeof error) {
                case 'string':
                    message = error;
                    break;
                case 'undefined':
                    message = 'undefined';
                    break;
                case 'number':
                case 'boolean':
                case 'bigint':
                case 'symbol':
                    message = error.toString();
                    break;
                default:
                    message = stringify(error);
                    break;
            }
        }
        super(message);
        if (error) {
            this.cause = error;
        }
        Object.defineProperty(this, 'name', {
            configurable: true,
            enumerable: false,
            value: name,
            writable: true,
        });
    }
}
