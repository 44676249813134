import { jsx as _jsx } from "react/jsx-runtime";
import { ApolloProvider } from '@apollo/client';
import { ChakraProvider } from '@chakra-ui/provider';
import { createRoot } from 'react-dom/client';
import { Outlet, RouterProvider } from 'react-router-dom';
import { Suspense } from 'react';
import { ErrorBoundary } from './components/ErrorBoundary';
import { AuthContextProvider } from './contexts/AuthContext';
import { BackForwardHistoryContextProvider } from './contexts/BackForwardHistoryContext';
import { ScreenContextProvider } from './contexts/ScreenContext';
import { createRouter } from './routes';
import { theme } from './styles/theme';
import { apolloClient } from './utils/apollo-client';
import { initializeSentry } from './utils/errorReporting';
import './utils/firebase';
import './utils/register-service-worker';
import { AppContextProvider } from './react-contexts/AppContext';
initializeSentry();
const AppProvider = () => (_jsx(ErrorBoundary, { children: _jsx(ApolloProvider, { client: apolloClient, children: _jsx(AuthContextProvider, { children: _jsx(AppContextProvider, { children: _jsx(ScreenContextProvider, { children: _jsx(BackForwardHistoryContextProvider, { children: _jsx(Suspense, { children: _jsx(Outlet, {}) }) }) }) }) }) }) }));
const router = createRouter(_jsx(AppProvider, {}));
const App = () => (_jsx(ChakraProvider, { theme: theme, children: _jsx(RouterProvider, { router: router }) }));
const root = createRoot(document.getElementById('root'));
root.render(_jsx(App, {}));
