export class MikanGraphQLError extends Error {
    constructor(apolloError) {
        super();
        this.name = new.target.name;
        this.apolloError = apolloError;
        if (apolloError !== undefined) {
            this.apolloErrorJSONString = JSON.stringify(apolloError);
        }
    }
}
