import { browserTracingIntegration, getCurrentScope, init, replayIntegration } from '@sentry/browser';
import { config } from '../config';
/**
 * Sentry に通知しないエラーのフィルタリング
 */
const IGNORE_ERRORS = [
    // Firebase App Check 内で error がキャッチされず UnhandledRejection となる
    /^(UnhandledRejection: )?Non-Error promise rejection captured with value/,
    /^(Error: )?UnknownError: Error looking up record in object store by key range/,
    // 音声再生/入力に失敗した際のエラー．件数が多くquotaを逼迫するため修正するまで無視
    /(Error: )?AbortError: The operation was aborted/,
    /(Error: )?NotSupportedError: The operation is not supported/,
    // 存在しない chunk js をリクエストした際 html が返り SyntaxError となる
    /^Unexpected token '<'$/,
    // 認証エラー
    /^認証に失敗しました/,
    /^無効なトークンにより認証に失敗しました/,
    // ネットワークエラー
    /^ネットワーク接続が切れました/,
    /^ネットワークがオフラインです/,
];
const debugLogger = (event) => {
    var _a;
    const log = {
        level: event.level,
        message: event.message,
        exception: (_a = event.exception) === null || _a === void 0 ? void 0 : _a.values,
        contexts: event.contexts,
    };
    // eslint-disable-next-line no-console
    console.debug('🚨 ErrorReporting', log);
};
const handleBeforeSend = (event) => {
    if (config.get('SENTRY_DRY')) {
        debugLogger(event);
        return null;
    }
    return event;
};
let scope = null;
export const initializeSentry = () => {
    init({
        environment: config.get('SENTRY_ENV'),
        dsn: config.get('SENTRY_DSN'),
        integrations: [browserTracingIntegration(), replayIntegration()],
        release: `${APP_NAME}@${APP_VERSION}`,
        tracesSampleRate: 1.0,
        ignoreErrors: IGNORE_ERRORS,
        beforeSend: handleBeforeSend,
    });
    scope = getCurrentScope();
    return scope;
};
const provideSentry = () => {
    if (scope != null) {
        return scope;
    }
    return initializeSentry();
};
export const setNotifyErrorUser = (userDescriptor) => {
    const scope = provideSentry();
    if (userDescriptor == null || userDescriptor.userId == null) {
        // unset user
        scope.setUser(null);
        return;
    }
    scope.setUser({
        id: userDescriptor.userId.toString(),
        extra: {
            firebaseUid: userDescriptor.firebaseUserId,
            kingdomMigrationStatus: userDescriptor.kingdomMigrationStatus,
        },
    });
};
export const notifyError = (error, descriptor = {}) => {
    const scope = provideSentry();
    if (descriptor.userId != null) {
        scope.setUser({
            id: descriptor.userId.toString(),
            extra: {
                firebaseUid: descriptor.firebaseUserId,
                kingdomMigrationStatus: descriptor.kingdomMigrationStatus,
            },
        });
    }
    if (descriptor.severity != null) {
        scope.setLevel(descriptor.severity);
    }
    if (descriptor.message != null && descriptor.message !== '') {
        scope.setExtra('message', descriptor.message);
    }
    if (error instanceof Error) {
        scope.setContext('error fields', Object.entries(error).reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {}));
        scope.captureException(error);
    }
    else {
        scope.captureMessage(error);
    }
};
