var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApolloError, gql } from '@apollo/client';
import { MikanGraphQLError } from '../../../errors/mikan-graphql-error';
import { UnknownError } from '../../../errors/unknown-error';
import { KingdomMigrationStatus } from '../../../gql/generated/graphql';
import { apolloClient } from '../../../utils/apollo-client';
import { config } from '../../../config';
const KINGDOM_MIGRATION_INFO = gql `
  query kingdomMigrationInfo {
    kingdomMigrationInfo {
      status
      ... on KingdomMigrationInfoExecuting {
        progress
      }
    }
  }
`;
/**
 * Kingdom 移行ステータス取得処理
 *
 * @description Kingdom がリリースされるまでは `ENABLE_KINGDOM` フラグでクエリを呼び出すかどうか判別する
 * ローカルで試すときは .env ファイルに ENABLE_KINGDOM="true" を設定すること
 *
 * @return {KingdomMigrationStatus} status: 移行ステータス
 * @return {number} progress: 移行進捗度 (KingdomMigrationStatus = Executing のときのみ取得可)
 * @return {string} dataSource: データソース (firestore か bff [CloudSQL])
 */
export const kingdomMigrationInfo = () => __awaiter(void 0, void 0, void 0, function* () {
    if (!config.get('ENABLE_KINGDOM')) {
        return {
            status: KingdomMigrationStatus.NotStarted,
            progress: 0,
            dataSource: 'firestore',
        };
    }
    try {
        const { data: { kingdomMigrationInfo }, } = yield apolloClient.query({
            query: KINGDOM_MIGRATION_INFO,
            fetchPolicy: 'no-cache',
        });
        switch (kingdomMigrationInfo.status) {
            case KingdomMigrationStatus.Executing:
            case KingdomMigrationStatus.Completed:
                return {
                    dataSource: 'bff',
                    status: kingdomMigrationInfo.status,
                    progress: kingdomMigrationInfo.__typename === 'KingdomMigrationInfoExecuting'
                        ? kingdomMigrationInfo.progress
                        : undefined,
                };
            case KingdomMigrationStatus.NotStarted:
            case KingdomMigrationStatus.Skipped:
            case KingdomMigrationStatus.Failed:
                return {
                    dataSource: 'firestore',
                    status: kingdomMigrationInfo.status,
                    progress: undefined,
                };
        }
    }
    catch (error) {
        if (error instanceof ApolloError) {
            throw new MikanGraphQLError(error);
        }
        throw new UnknownError();
    }
});
