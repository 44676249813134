var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { initializeApp } from 'firebase/app';
import { ReCaptchaV3Provider, initializeAppCheck } from 'firebase/app-check';
import { getAuth as auth, signInAnonymously as sa, signInWithCustomToken as swc } from 'firebase/auth';
import { getFirestore as firestore } from 'firebase/firestore';
import { getStorage as storage } from 'firebase/storage';
import { config } from '../config';
const firebaseApp = initializeApp({
    apiKey: config.get('FIREBASE_API_KEY'),
    authDomain: config.get('FIREBASE_AUTH_DOMAIN'),
    databaseURL: config.get('FIREBASE_DATABASE_URL'),
    projectId: config.get('FIREBASE_PROJECT_ID'),
    storageBucket: config.get('FIREBASE_STORAGE_BUCKET'),
    messagingSenderId: config.get('FIREBASE_MESSAGING_SENDER_ID'),
    appId: config.get('FIREBASE_APP_ID'),
    measurementId: config.get('FIREBASE_MEASUREMENT_ID'),
});
if (config.get('ENABLE_APP_CHECK')) {
    if (config.get('FIREBASE_APP_CHECK_DEBUG')) {
        window.FIREBASE_APPCHECK_DEBUG_TOKEN = true;
    }
    initializeAppCheck(firebaseApp, {
        provider: new ReCaptchaV3Provider(config.get('RE_CAPTCHA_SITE_KEY')),
        isTokenAutoRefreshEnabled: true,
    });
}
export const getApp = () => firebaseApp;
export const getAuth = () => auth(firebaseApp);
export const getFirestore = () => firestore(firebaseApp);
export const getStorage = () => storage(firebaseApp);
export const signInWithCustomToken = (token) => __awaiter(void 0, void 0, void 0, function* () {
    const auth = getAuth();
    return yield swc(auth, token);
});
export const signInAnonymously = () => __awaiter(void 0, void 0, void 0, function* () {
    const auth = getAuth();
    return yield sa(auth);
});
