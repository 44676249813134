var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ApolloError, gql } from '@apollo/client';
import { MikanGraphQLError } from '../../../errors/mikan-graphql-error';
import { UnknownError } from '../../../errors/unknown-error';
import { apolloClient } from '../../../utils/apollo-client';
const GET_USER = gql `
  query getUser {
    user {
      id
      nickname
      iconImageUrl
      subscription {
        status
        expiresUnixTimeMs
        isTrial
        productId
        activeSubscriptionReceipts {
          productId
        }
      }
    }
  }
`;
/**
 * CloudSQL ユーザー取得処理
 */
export const getUser = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const response = yield apolloClient.query({
            query: GET_USER,
            fetchPolicy: 'no-cache',
        });
        if (response.data === undefined ||
            response.data.user === undefined ||
            response.data.user.id === undefined ||
            response.data.user.id.length === 0) {
            return undefined;
        }
        return response.data.user;
    }
    catch (error) {
        // ユーザーが存在しない場合、undefined を返す
        if (error instanceof ApolloError &&
            error.graphQLErrors !== undefined &&
            error.graphQLErrors.length > 0 &&
            ((_a = error.graphQLErrors[0].extensions) === null || _a === void 0 ? void 0 : _a.code) === 404) {
            return undefined;
        }
        if (error instanceof ApolloError) {
            throw new MikanGraphQLError(error);
        }
        throw new UnknownError();
    }
});
