const baseSpace = 8;
export const space = {
    none: '0px',
    px: '1px',
    '0x': '0px',
    '0.25x': `${baseSpace * 0.25}px`, // 2px
    '0.5x': `${baseSpace * 0.5}px`, // 4px
    '1x': `${baseSpace * 1}px`, // 8px
    '1.5x': `${baseSpace * 1.5}px`, // 12px
    '2x': `${baseSpace * 2}px`, // 16px
    '2.5x': `${baseSpace * 2.5}px`, // 20px
    '3x': `${baseSpace * 3}px`, // 24px
    '3.5x': `${baseSpace * 3.5}px`, // 28px
    '4x': `${baseSpace * 4}px`, // 32px
};
export default space;
