import { BaseError } from './base-error';
export const VALIDATION_ITEMS = {
    EMPTY_BOOK_ID: 'EmptyBookId',
    EMPTY_CHAPTER_ID: 'EmptyChapterId',
    EMPTY_EXAM_ID: 'EmptyExamId',
    EMPTY_ID_TOKEN: 'EmptyIdToken',
    EMPTY_NICKNAME: 'EmptyNickname',
    EMPTY_PASSWORD: 'EmptyPassword',
    EMPTY_ROOM_CODE: 'EmptyRoomCode',
    EMPTY_SCHOOL_ID: 'EmptySchoolId',
    EMPTY_TAG_ID: 'EmptyTagId',
    EMPTY_USER_ID: 'EmptyUserId',
    EMPTY_WORD_ID: 'EmptyWordId',
    INVALID_NODE_ID: 'InvalidNodeId',
};
const messages = {
    [VALIDATION_ITEMS.EMPTY_BOOK_ID]: '教材IDが設定されていません',
    [VALIDATION_ITEMS.EMPTY_CHAPTER_ID]: 'チャプターIDが空です',
    [VALIDATION_ITEMS.EMPTY_EXAM_ID]: 'テストIDが設定されていません',
    [VALIDATION_ITEMS.EMPTY_ID_TOKEN]: 'IDトークンが空です',
    [VALIDATION_ITEMS.EMPTY_PASSWORD]: 'パスワードが入力されていません',
    [VALIDATION_ITEMS.EMPTY_ROOM_CODE]: '教室コードが設定されていません',
    [VALIDATION_ITEMS.EMPTY_SCHOOL_ID]: 'スクールIDが入力されていません',
    [VALIDATION_ITEMS.EMPTY_TAG_ID]: 'タグが入力されていません',
    [VALIDATION_ITEMS.EMPTY_USER_ID]: 'ユーザーIDが入力されていません',
    [VALIDATION_ITEMS.EMPTY_WORD_ID]: '単語IDが空です',
    [VALIDATION_ITEMS.INVALID_NODE_ID]: '無効なノードIDです',
};
export class ValidationError extends BaseError {
    constructor(item, message) {
        super(message);
        this.item = item;
        this.message = messages[item] || '';
    }
}
