var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { osVersion, osName, browserName } from 'react-device-detect';
import { HTTP_METHODS } from '../../constants/http';
import { API_HEADER_KEYS, API_PATHS } from '../../constants/mikan-bff';
import { ValidationError, VALIDATION_ITEMS } from '../../errors/validation-error';
import mikanBff from '../../utils/mikan-bff';
import { getDeviceIdFromStorage } from '../../utils/app-storage';
export const signin = (idToken, nickname) => __awaiter(void 0, void 0, void 0, function* () {
    if (idToken.length === 0) {
        throw new ValidationError(VALIDATION_ITEMS.EMPTY_ID_TOKEN);
    }
    if (nickname.length === 0) {
        nickname = 'mikan';
    }
    const duid = getDeviceIdFromStorage();
    yield mikanBff.request({
        method: HTTP_METHODS.PUT,
        url: API_PATHS.SIGNIN,
        headers: {
            [API_HEADER_KEYS.AUTHORIZATION]: `Bearer ${idToken}`,
            [API_HEADER_KEYS.CONTENT_TYPE]: 'application/json',
            [API_HEADER_KEYS.X_DEVICE_APP_BUNDLE_ID]: APP_NAME,
            [API_HEADER_KEYS.X_DEVICE_APP_VERSION]: APP_VERSION,
            [API_HEADER_KEYS.X_DEVICE_OS_VERSION]: osVersion,
            [API_HEADER_KEYS.X_DEVICE_PLATFORM]: PLATFORM,
        },
        data: {
            nickname,
            duid,
            device_name: `${osName} ${browserName}`,
        },
    });
});
