import breakpoints from './breakpoints';
import colors from './colors';
import radii from './radii';
import space from './space';
export const foundations = {
    breakpoints,
    colors,
    radii,
    space,
};
