import { createBrowserRouter } from 'react-router-dom';
import { ErrorBoundary } from './components/ErrorBoundary';
const lazyComponent = (componentImport) => {
    return () => componentImport().then((v) => ({ Component: v.default }));
};
export const createRouter = (Provider) => createBrowserRouter([
    {
        element: Provider,
        ErrorBoundary,
        children: [
            {
                path: '/',
                lazy: lazyComponent(() => import('./pages/Index')),
            },
            {
                path: '/login',
                lazy: lazyComponent(() => import('./pages/login/Login')),
            },
            {
                path: '/registration',
                lazy: lazyComponent(() => import('./pages/registration/Registration')),
            },
            {
                path: '/mypage',
                lazy: lazyComponent(() => import('./pages/mypage/Mypage')),
            },
            {
                path: '/books',
                children: [
                    {
                        index: true,
                        lazy: lazyComponent(() => import('./pages/books/Books')),
                    },
                    {
                        path: ':bookId',
                        children: [
                            {
                                index: true,
                                lazy: lazyComponent(() => import('./pages/book/Book')),
                            },
                            {
                                path: 'audios',
                                children: [
                                    {
                                        index: true,
                                        lazy: lazyComponent(() => import('./pages/audios/Audios')),
                                    },
                                    {
                                        path: ':audioResourceCategoryId',
                                        lazy: lazyComponent(() => import('./pages/audio/Audio')),
                                    },
                                ],
                            },
                            {
                                path: 'learning',
                                lazy: lazyComponent(() => import('./pages/learning/Learning')),
                            },
                            {
                                path: 'questions',
                                lazy: lazyComponent(() => import('./pages/questions/Questions')),
                            },
                            {
                                path: 'ebook-resources/:ebookResourceId',
                                lazy: lazyComponent(() => import('./pages/ebook-resource/EbookResource')),
                            },
                            {
                                path: 'question-chapters/:questionChapterId',
                                lazy: lazyComponent(() => import('./pages/question-chapter/QuestionChapter')),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'studied-records',
                lazy: lazyComponent(() => import('./pages/studied-records/StudiedRecords')),
            },
            {
                path: 'exams',
                children: [
                    {
                        index: true,
                        lazy: lazyComponent(() => import('./pages/exams/Exams')),
                    },
                    {
                        path: ':examId',
                        children: [
                            {
                                index: true,
                                lazy: lazyComponent(() => import('./pages/exam/Exam')),
                            },
                            {
                                path: 'results/:examResultId',
                                lazy: lazyComponent(() => import('./pages/exam-result/ExamResult')),
                            },
                            {
                                path: 'rankings',
                                lazy: lazyComponent(() => import('./pages/exam-rankings/ExamRankings')),
                            },
                            {
                                path: 'history',
                                lazy: lazyComponent(() => import('./pages/exam-history/ExamHistory')),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'school-exam-rankings',
                lazy: lazyComponent(() => import('./pages/school-exam-rankings/SchoolExamRankings')),
            },
            {
                path: 'assignments',
                lazy: lazyComponent(() => import('./pages/assignmentList')),
            },
            {
                path: 'quizzes',
                children: [
                    {
                        index: true,
                        lazy: lazyComponent(() => import('./pages/quizzes/Quizzes')),
                    },
                    {
                        path: ':quizId/periods/:quizPeriodId',
                        children: [
                            {
                                index: true,
                                lazy: lazyComponent(() => import('./pages/quiz/Quiz')),
                            },
                            {
                                path: 'results/:quizStudiedRecordId',
                                lazy: lazyComponent(() => import('./pages/quiz-result/QuizResult')),
                            },
                            {
                                path: 'rankings',
                                lazy: lazyComponent(() => import('./pages/quiz-rankings/QuizRankings')),
                            },
                        ],
                    },
                ],
            },
        ],
    },
]);
