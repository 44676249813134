export const ltrim = (str, trim) => {
    return str.indexOf(trim) === 0 ? str.slice(trim.length) : str;
};
export const rtrim = (str, trim) => {
    const i = str.length - trim.length;
    return str.lastIndexOf(trim, i) === i ? str.slice(0, i) : str;
};
/**
 * 文字数 (バイト) に応じた4択ボタンのフォントサイズ計算処理
 * 半角は 1 文字、全角は 2 文字として扱う
 * 40 文字以下は既存の設定どおり 2rem
 * 41 文字以上は、文字数が多いほどサイズが小さくなるが、最低 1.2rem とする
 * @param text 対象文字列
 * @returns 計算後のフォントサイズ (rem)
 */
export const calculateChoiceButtonFontSize = (text) => {
    if (!text) {
        return '2rem';
    }
    let len = 0;
    for (let i = 0; i < text.length; i++) {
        // 正規表現で, 半角スペース「 」からチルダ「~」までを指定することで半角英数字・記号を判別, それ以外を全角とする
        text[i].match(/[ -~]/) ? (len += 1) : (len += 2);
    }
    if (len <= 40) {
        return '2rem';
    }
    return `${Math.max(1.2, 2 * (40 / len))}rem`;
};
export const containsJapanese = (str) => {
    if (!str) {
        return false;
    }
    return str.match(/[\u30a0-\u30ff\u3040-\u309f\u3005-\u3006\u30e0-\u9fcf]+/) ? true : false;
};
/**
 * 文字数 (バイト) に応じた語句整序の単語のフォントサイズ計算処理
 * 半角は 1 文字、全角は 2 文字として扱う
 * 12 文字以下は既存の設定どおり 1.4rem
 * 13 文字以上は、文字数が多いほどサイズが小さくなるが、最低 1.2rem とする
 * @param text 対象文字列
 * @returns 計算後のフォントサイズ (rem)
 */
export const calculateRearrangeItemFontSize = (text) => {
    if (!text) {
        return '1.4rem';
    }
    let len = 0;
    for (let i = 0; i < text.length; i++) {
        text[i].match(/[ -~]/) ? (len += 1) : (len += 2);
    }
    if (len <= 12) {
        return '1.4rem';
    }
    return `${Math.max(1.2, 1.6 * (12 / len))}rem`;
};
/**
 * 文字数 (バイト) に応じた ExamChoice の問題文のフォントサイズ計算処理
 * 半角は 1 文字、全角は 2 文字として扱う
 * 30 文字以下は既存の設定どおり 2.4rem
 * 31 文字以上は、文字数が多いほどサイズが小さくなる
 * @param text 対象文字列
 * @returns 計算後のフォントサイズ (rem)
 */
export const calculateExamChoiceMainLabelFontSize = (text) => {
    const baseFontSize = '2.4rem';
    if (!text) {
        return baseFontSize;
    }
    let len = 0;
    for (let i = 0; i < text.length; i++) {
        text[i].match(/[ -~]/) ? (len += 1) : (len += 2);
    }
    if (len <= 30) {
        return baseFontSize;
    }
    return `${2.4 - (len - 31) / 150}rem`;
};
