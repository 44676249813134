var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx } from "react/jsx-runtime";
import { onAuthStateChanged, signOut } from 'firebase/auth';
import { createContext, useCallback, useEffect, useMemo, useReducer } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { KingdomMigrationStatusContent } from '../components/KingdomMigrationStatusContent';
import { fetchUserSchools } from '../datasources/api/user-schools';
import { fetchUserByFirebaseUid } from '../datasources/api/users';
import { getUser } from '../datasources/bff/graphql/get-user';
import { kingdomMigrationInfo as getKingdomMigrationInfo } from '../datasources/bff/graphql/kingdom-migration-info';
import { signin } from '../datasources/bff/signin';
import { UnauthorizedError } from '../errors/unauthorized-error';
import { KingdomMigrationStatus } from '../gql/generated/graphql';
import { authActions, authReducer, initialState } from '../reducers/auth';
import { isWebView } from '../utils/device';
import { normalizeError } from '../utils/error';
import { notifyError, setNotifyErrorUser } from '../utils/errorReporting';
import { getAuth, signInWithCustomToken } from '../utils/firebase';
import { urlgen } from '../utils/url';
export const AuthContext = createContext(initialState);
export const AuthUpdateContext = createContext({});
/** @deprecated router で制御する */
const PUBLIC_PAGE_PATHS = [urlgen('login'), urlgen('quizzes')];
export const AuthContextProvider = ({ children }) => {
    var _a, _b, _c, _d;
    const [authState, dispatch] = useReducer(authReducer, initialState);
    const location = useLocation();
    const navigate = useNavigate();
    const [cookies, setCookies] = useCookies(['token']);
    const isPublicPagePath = useMemo(() => {
        return PUBLIC_PAGE_PATHS.some((path) => location.pathname.startsWith(path));
    }, [location.pathname]);
    const logoutAndRedirect = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        const auth = getAuth();
        yield signOut(auth);
        dispatch(authActions.logoutAction());
        navigate(urlgen('login'), { replace: true });
    }), [navigate]);
    const fetchAuthData = useCallback((firebaseUser) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const [user, schoolUser, schools, kingdomMigrationInfo] = yield Promise.all([
            getUser(),
            fetchUserByFirebaseUid(firebaseUser.uid),
            fetchUserSchools(firebaseUser.uid),
            getKingdomMigrationInfo(), // KINGDOM_TODO: Kingdom 移行後削除
        ]);
        // TODO: 暫定的に school は複数あるうち先頭のものを取得している
        const school = (_a = schools[0]) !== null && _a !== void 0 ? _a : null;
        return {
            mikanUser: user,
            schoolUser,
            school,
            kingdomMigrationInfo,
        };
    }), []);
    const tryLogin = useCallback((firebaseUser) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            dispatch(authActions.loginInitAction());
            const { mikanUser, schoolUser, school, kingdomMigrationInfo } = yield fetchAuthData(firebaseUser);
            if (schoolUser == null) {
                // school ユーザーが作成されていない & 認証不要なページ以外にアクセスした場合は問答無用でログアウト
                if (!isPublicPagePath) {
                    yield logoutAndRedirect();
                    throw new UnauthorizedError();
                }
                return;
            }
            // WebView からアクセスされる特定のページ以外、
            // school ユーザーの氏名が入力されていない場合は未認証としてログイン画面に戻す
            if (!(isWebView && isPublicPagePath) && (!schoolUser.firstName || !schoolUser.lastName)) {
                yield logoutAndRedirect();
                throw new UnauthorizedError();
            }
            // CloudSQL ユーザー が作成されていなければ作成
            if (mikanUser == null) {
                const idToken = yield firebaseUser.getIdToken();
                yield signin(idToken, schoolUser.name);
            }
            dispatch(authActions.loginSuccessAction({
                user: schoolUser,
                firebaseUser,
                school,
                kingdomMigrationInfo,
            }));
            setNotifyErrorUser({
                userId: schoolUser.userId,
                firebaseUserId: firebaseUser.uid,
                kingdomMigrationStatus: kingdomMigrationInfo.status,
            });
        }
        catch (error) {
            const normalizedError = normalizeError(error);
            notifyError(normalizedError);
            dispatch(authActions.loginFailureAction(normalizedError));
        }
    }), [fetchAuthData, isPublicPagePath, logoutAndRedirect]);
    useEffect(() => {
        const auth = getAuth();
        if (auth.currentUser) {
            tryLogin(auth.currentUser);
        }
        const unsubscribe = onAuthStateChanged(auth, (user) => __awaiter(void 0, void 0, void 0, function* () {
            try {
                // webview での認証処理として実装しているので認証状態の監視はおこなっていない
                // https://github.com/mikan-tech/mikan-web/pull/668#discussion_r1685175438
                unsubscribe();
                // WebView の場合、cookie に firebase auth の custom token があれば
                // 現在認証しているか否かに関わらずシームレスログインを行う
                // TODO: Android WebView の判定は古い OS だと動かない可能性あり & User-Agent Client Hints が導入されたらそっちに切り替える
                if (cookies.token && isWebView) {
                    const userCredential = yield signInWithCustomToken(cookies.token);
                    yield tryLogin(userCredential.user);
                    setCookies('token', '');
                    return;
                }
                if (!user) {
                    // 認証済みでない場合、ログイン画面に飛ばす
                    navigate(urlgen('login'), { replace: true });
                    return;
                }
                yield tryLogin(user);
            }
            catch (error) {
                const normalizedError = normalizeError(error);
                notifyError(normalizedError);
            }
        }));
        return unsubscribe;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    /**
     * 現在保持されている Kingdom 移行状況のデータソースが、最新のものと変わっていないかどうかを取得する処理
     */
    const checkDataSourceChanged = useCallback(() => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        const currentDataSource = (_a = authState.kingdomMigrationInfo) === null || _a === void 0 ? void 0 : _a.dataSource;
        const migrationInfo = yield getKingdomMigrationInfo();
        dispatch(authActions.updateKingdomMigrationInfoAction(migrationInfo));
        return currentDataSource !== migrationInfo.dataSource;
    }), [(_a = authState.kingdomMigrationInfo) === null || _a === void 0 ? void 0 : _a.dataSource]);
    // Kingdom 移行中 or 失敗時は専用の移行画面を表示する
    // KINGDOM_TODO: kingdom 移行完了後削除する
    if (((_b = authState.kingdomMigrationInfo) === null || _b === void 0 ? void 0 : _b.status) === KingdomMigrationStatus.Executing) {
        return (_jsx(KingdomMigrationStatusContent, { status: authState.kingdomMigrationInfo.status, schoolUserId: (_d = (_c = authState.school) === null || _c === void 0 ? void 0 : _c.userIdentifier) !== null && _d !== void 0 ? _d : null, signOutAndGotoLogin: logoutAndRedirect }));
    }
    return (_jsx(AuthContext.Provider, { value: authState, children: _jsx(AuthUpdateContext.Provider, { value: { authDispatch: dispatch, getDataSourceChanged: checkDataSourceChanged }, children: children }) }));
};
