/** @deprecated use `urlgen(...)` */
export const ROUTES = {
    AUDIOS: 'audios',
    BOOKS: 'books',
    MYPAGE: 'mypage',
    STUDIED_RECORDS: 'studied-records',
    EXAMS: 'exams',
};
export const ROUTE_DEFINITION = {
    top: '/',
    login: '/login',
    registeration: '/registration',
    mypage: '/mypage',
    books: '/books',
    book: ({ bookId }) => `/books/${bookId}`,
    'book.audios': ({ bookId }) => `/books/${bookId}/audio`,
    'book.audio': ({ bookId, audioResourceCategoryId }) => `books/${bookId}/audios/${audioResourceCategoryId}`,
    'book.learning': ({ bookId }) => `/books/${bookId}/learning`,
    'book.questions': ({ bookId }) => `/books/${bookId}/questions`,
    'book.ebook-resource': ({ bookId, ebookResourceId }) => `/books/${bookId}/ebook-resources/${ebookResourceId}`,
    'book.question-chapter': ({ bookId, questionChapterId }) => `/books/${bookId}/question-chapters/${questionChapterId}`,
    'studied-records': '/studied-records',
    // 配布テスト関連
    exams: '/exams',
    exam: ({ examId }) => `/exams/${examId}`,
    'exam.result': ({ examId, resultIndex }) => `/exams/${examId}/results/${resultIndex}`,
    'exam.ranking': ({ examId }) => `/exams/${examId}/rankings`,
    'exam.history': ({ examId }) => `/exams/${examId}/history`,
    'school-exam-rankings': '/school-exam-rankings',
    assignments: '/assignments',
    quizzes: '/quizzes',
    'quiz.period': ({ quizId, periodId }) => `/quizzes/${quizId}/periods/${periodId}`,
    'quiz.period.result': ({ quizId, periodId, resultId }) => `/quizzes/${quizId}/periods/${periodId}/results/${resultId}`,
    'quiz.period.ranking': ({ quizId, periodId }) => `/quizzes/${quizId}/periods/${periodId}/rankings`,
};
