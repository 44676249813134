export const lightTheme = {
    orange: {
        100: '#FFF6F2',
        200: '#FFECE4',
        300: '#FFD8C6',
        400: '#FFC1A1',
        500: '#FFA772',
        600: '#FF8900',
        700: '#E47B00',
        800: '#C66A00',
        900: '#A15700',
        1000: '#723D00',
    },
    red: {
        100: '#FDF3F3',
        200: '#FBE7E6',
        300: '#F7CDCA',
        400: '#F3AEAA',
        500: '#EF8982',
        600: '#EB5545',
        700: '#D24C3E',
        800: '#B64235',
        900: '#95362C',
        1000: '#69261F',
    },
    blue: {
        100: '#F4F6FE',
        200: '#E8EEFE',
        300: '#CEDBFC',
        400: '#B0C6FB',
        500: '#8DAFF9',
        600: '#5C95F8',
        700: '#5285DE',
        800: '#4773C0',
        900: '#3A5E9D',
        1000: '#29436F',
    },
    green: {
        100: '#F3F9F4',
        200: '#E7F2E8',
        300: '#CDE5CF',
        400: '#AED7B2',
        500: '#89C790',
        600: '#55B762',
        700: '#4CA458',
        800: '#428E4C',
        900: '#36743E',
        1000: '#26522C',
    },
    lightGreen: {
        100: '#F6FBF2',
        200: '#EDF7E5',
        300: '#D9EFC8',
        400: '#C4E7A6',
        500: '#ABDE7B',
        600: '#8FD535',
        700: '#80BF2F',
        800: '#6FA529',
        900: '#5A8722',
        1000: '#405F18',
    },
    gray: {
        100: '#F3F3F3',
        200: '#E5E5E5',
        300: '#C9C9C9',
        400: '#A7A7A7',
        500: '#7D7D7D',
        600: '#393939',
        700: '#333333',
        800: '#2C2C2C',
        900: '#242424',
        1000: '#191919',
    },
    whiteAlpha: {
        100: '#FFFFFF05',
        200: '#FFFFFF0F',
        300: '#FFFFFF1C',
        400: '#FFFFFF2B',
        500: '#FFFFFF5E',
        600: '#FFFFFF8C',
        700: '#FFFFFFAD',
        800: '#FFFFFFD1',
        900: '#FFFFFFEB',
    },
    blackAlpha: {
        100: '#00000005',
        200: '#0000000F',
        300: '#0000001C',
        400: '#0000002B',
        500: '#0000005E',
        600: '#0000008C',
        700: '#000000AD',
        800: '#000000D1',
        900: '#000000EB',
    },
    white: '#ffffff',
    black: '#000000',
};
